import * as actionTypes from "../../actionTypes/admin/transactionActionTypes";
import * as error from "../errorActions";
import { baseURL, http } from "../../../config/axios";
import * as notifications from "../../../components/notifications/notifications";
import { saveAs } from "file-saver";
import { download } from "../utilityActions";

export const fetchTransactionCounts = async (dispatch) => {
  try {
    const res = await http.get("admin/transactions/counts");
    dispatch({
      type: actionTypes.SET_TRANSACTION_COUNTS,
      payload: res.data.data,
    });
  } catch (err) {}
};

export const _pending =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_PENDING, payload: true });
      const res = await http.get(
        `admin/transactions/pending/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_PENDING, payload: false });
      dispatch({
        type: actionTypes.SET_PENDING,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_PENDING, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _suspended =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_SUSPENDEND, payload: true });
      const res = await http.get(
        `admin/transactions/suspended/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_SUSPENDEND, payload: false });
      dispatch({
        type: actionTypes.SET_SUSPENDEND,
        payload: res.data.data.data,
      });
    } catch (err) {
      // console.log(err);
      dispatch({ type: actionTypes.FETCHING_SUSPENDEND, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _completed =
  (page = 1, country_code) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_COMPLETED, payload: true });
      let res;
      if (country_code) {
        res = await http.get(
          `admin/transactions/filter_list/completed/${country_code}?page=${page}`
        );
      } else {
        res = await http.get(
          `admin/transactions/filter_list/completed?page=${page}`
        );
      }

      dispatch({ type: actionTypes.FETCHING_COMPLETED, payload: false });
      dispatch({
        type: actionTypes.SET_COMPLETED,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_COMPLETED, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _walletFundings =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_WALLET_FUNDINGS, payload: true });
      const res = await http.get(
        `/admin/wallets/fundings?paginate=1&per_page=10&page=${page}`
      );

      dispatch({ type: actionTypes.FETCHING_WALLET_FUNDINGS, payload: false });
      dispatch({
        type: actionTypes.SET_WALLET_FUNDINGS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_WALLET_FUNDINGS, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _swappings =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_WALLET_SWAPPINGS, payload: true });
      const res = await http.get(
        `/admin/wallets/swappings?paginate=1&per_page=10&page=${page}`
      );

      dispatch({ type: actionTypes.FETCHING_WALLET_SWAPPINGS, payload: false });
      dispatch({
        type: actionTypes.SET_WALLET_SWAPPINGS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_WALLET_SWAPPINGS, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };
export const getTransactionCountries = (trx_status) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_COMPLETED, payload: true });
    const res = await http.get(`/admin/transactions/get_country/${trx_status}`);
    dispatch({ type: actionTypes.FETCHING_COMPLETED, payload: false });
    dispatch({
      type: actionTypes.SET_TRANSACTION_COUNTRIES,
      payload: res.data,
    });
  } catch (err) {
    dispatch({ type: actionTypes.FETCHING_FAILED, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const _failed =
  (page = 1, country_code) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_FAILED, payload: true });
      let res;
      if (country_code) {
        res = await http.get(
          `admin/transactions/filter_list/failed/${country_code}?page=${page}`
        );
      } else {
        res = await http.get(
          `admin/transactions/filter_list/failed?page=${page}`
        );
      }

      // console.log(res.data.data.data);
      dispatch({ type: actionTypes.FETCHING_FAILED, payload: false });
      dispatch({
        type: actionTypes.SET_FAILED,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_FAILED, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _failedPayouts =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_FAILED_PAYOUTS, payload: true });
      const res = await http.get(
        `admin/transactions/failed_payout/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_FAILED_PAYOUTS, payload: false });
      dispatch({
        type: actionTypes.SET_FAILED_PAYOUTS,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_FAILED_PAYOUTS, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _inprogress =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_INPROGRESS, payload: true });
      const res = await http.get(
        `admin/transactions/in_progress/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_INPROGRESS, payload: false });
      dispatch({
        type: actionTypes.SET_INPROGRESS,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_INPROGRESS, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _incomplete =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_INCOMPLETE, payload: true });
      const res = await http.get(
        `admin/transactions/incomplete/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_INCOMPLETE, payload: false });
      dispatch({
        type: actionTypes.SET_INCOMPLETE,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_INCOMPLETE, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _refunded =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({ type: actionTypes.FETCHING_REFUNDED, payload: true });
      const res = await http.get(
        `admin/transactions/refunded/paginate/10?page=${page}`
      );
      dispatch({ type: actionTypes.FETCHING_REFUNDED, payload: false });
      dispatch({
        type: actionTypes.SET_REFUNDED,
        payload: res.data.data.data,
      });
    } catch (err) {
      dispatch({ type: actionTypes.FETCHING_REFUNDED, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const _kpis = async (dispatch) => {
  try {
    dispatch({ type: actionTypes.FETCHING_KPIS, payload: true });
    const res = await http.get(`admin/dashboard_metas/kpis`);
    dispatch({ type: actionTypes.SET_KPIS, payload: res.data.data });
    dispatch({ type: actionTypes.FETCHING_KPIS, payload: false });
  } catch (err) {
    dispatch({ type: actionTypes.SET_KPIS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const search = (fd) => async (dispatch) => {
  try {
    const searchFor = localStorage.getItem("searchFor");
    dispatch({ type: actionTypes.SEARCHING_PENDING, payload: true });
    const res = await http.post(`/admin/transactions/search?page=${fd?.page}`, {
      ...fd,
      for: searchFor,
    });

    const data = res.data.data.data;

    if (searchFor === "pending")
      dispatch({ type: actionTypes.SET_PENDING, payload: data });
    if (searchFor === "suspended")
      dispatch({ type: actionTypes.SET_SUSPENDEND, payload: data });
    if (searchFor === "failed")
      dispatch({ type: actionTypes.SET_FAILED, payload: data });
    if (searchFor === "completed")
      dispatch({ type: actionTypes.SET_COMPLETED, payload: data });
    if (searchFor === "refunded")
      dispatch({ type: actionTypes.SET_REFUNDED, payload: data });
    if (searchFor === "in_progress")
      dispatch({ type: actionTypes.SET_INPROGRESS, payload: data });
    if (searchFor === "failed_payout")
      dispatch({ type: actionTypes.SET_FAILED_PAYOUTS, payload: data });

    dispatch({ type: actionTypes.SEARCHING_PENDING, payload: false });
  } catch (err) {
    dispatch({ type: actionTypes.SEARCHING_PENDING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const searchWalletTransactions = (fd) => async (dispatch) => {
  const searchFor = localStorage.getItem("searchFor");

  try {
    if (searchFor === "swappings") {
      dispatch({ type: actionTypes.FETCHING_WALLET_SWAPPINGS, payload: true });
    } else {
      dispatch({ type: actionTypes.FETCHING_WALLET_FUNDINGS, payload: true });
    }
    const res = await http.post(
      `/admin/wallets/search_${searchFor}?paginate=1&per_page=10&page=${fd?.page}`,
      {
        reference: fd?.param,
      }
    );

    if (searchFor === "fundings") {
      dispatch({
        type: actionTypes.SET_WALLET_FUNDINGS,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: actionTypes.SET_WALLET_SWAPPINGS,
        payload: res.data.data,
      });
    }
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    if (searchFor === "swappings") {
      dispatch({ type: actionTypes.FETCHING_WALLET_SWAPPINGS, payload: false });
    } else {
      dispatch({ type: actionTypes.FETCHING_WALLET_FUNDINGS, payload: false });
    }
  }
};

export const filterTransaction =
  (country_code, trx_status) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SEARCHING_PENDING, payload: true });
      const res = await http.get(
        `/admin/transactions/filter_list/${trx_status}/${country_code}`
      );
      const data = res.data.data.data;

      if (trx_status === "completed")
        dispatch({ type: actionTypes.SET_COMPLETED, payload: data });
      if (trx_status === "failed")
        dispatch({ type: actionTypes.SET_FAILED, payload: data });

      dispatch({ type: actionTypes.SEARCHING_PENDING, payload: false });
    } catch (err) {
      dispatch({ type: actionTypes.SEARCHING_PENDING, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const retryPayout = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_RETRYING, payload: true });
    dispatch({ type: actionTypes.SET_RETRIED, payload: false });
    const res = await http.get(`admin/transactions/confirm_payment/${fd}`);
    dispatch({ type: actionTypes.SET_RETRIED, payload: true });
    dispatch({ type: actionTypes.SET_RETRYING, payload: false });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_RETRYING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const forceComplete = (fd, checkVerify) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_FORCING, payload: true });
    dispatch({ type: actionTypes.SET_FORCED, payload: false });
    const res = await http.get(
      `admin/transactions/force_confirm_payment/${fd}/${checkVerify}`
    );
    dispatch({ type: actionTypes.SET_FORCED, payload: true });
    dispatch({ type: actionTypes.SET_FORCING, payload: false });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_FORCING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const sendToPending = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_SENDING_TO_PENDING, payload: true });
    dispatch({ type: actionTypes.SET_SENT_TO_PENDING, payload: false });
    const res = await http.get(`admin/transactions/to_pending/${fd}`);
    dispatch({ type: actionTypes.SET_SENDING_TO_PENDING, payload: false });
    dispatch({ type: actionTypes.SET_SENT_TO_PENDING, payload: true });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_SENDING_TO_PENDING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doSuspend = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_SUSPENDING, payload: true });
    dispatch({ type: actionTypes.SET_IS_SUSPENDED, payload: false });
    const res = await http.get(`admin/transactions/suspend/${fd}`);
    dispatch({ type: actionTypes.SET_SUSPENDING, payload: false });
    dispatch({ type: actionTypes.SET_IS_SUSPENDED, payload: true });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_SUSPENDING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doVerify = (fd, check, selectedTrnxPayout) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_VERIFYING, payload: true });
    dispatch({ type: actionTypes.SET_IS_VERIFIED, payload: false });
    const res = await http.get(
      `admin/transactions/verify_payment/${fd}/${selectedTrnxPayout}/${check}`
    );
    dispatch({ type: actionTypes.SET_VERIFYING, payload: false });
    dispatch({ type: actionTypes.SET_IS_VERIFIED, payload: true });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_VERIFYING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doForceFail = (trnxUuid) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_FORCE_FAILING, payload: true });
    dispatch({ type: actionTypes.SET_FORCE_FAILED, payload: false });
    const res = await http.get(
      `admin/transactions/fail_transaction/${trnxUuid}`
    );
    dispatch({ type: actionTypes.SET_FORCE_FAILING, payload: false });
    dispatch({ type: actionTypes.SET_FORCE_FAILED, payload: true });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_FORCE_FAILING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doTrash = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_TRASHING, payload: true });
    dispatch({ type: actionTypes.SET_IS_TRASHED, payload: false });
    const res = await http.get(`admin/transactions/delete/${fd}`);
    dispatch({ type: actionTypes.SET_TRASHING, payload: false });
    dispatch({ type: actionTypes.SET_IS_TRASHED, payload: true });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_TRASHING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doRefund = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_REFUNDING, payload: true });
    dispatch({ type: actionTypes.SET_IS_REFUNDED, payload: false });
    const res = await http.get(`admin/transactions/refund_make/${fd}`);
    dispatch({ type: actionTypes.SET_REFUNDING, payload: false });
    dispatch({ type: actionTypes.SET_IS_REFUNDED, payload: true });
    fetchTransactionCounts(dispatch);
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_REFUNDING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doComment = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_COMMENTING, payload: true });
    dispatch({ type: actionTypes.SET_IS_COMMENTED, payload: false });
    const res = await http.post(`admin/transactions/add_note/${fd.trnxId}`, fd);
    dispatch({ type: actionTypes.SET_COMMENTING, payload: false });
    dispatch({ type: actionTypes.SET_IS_COMMENTED, payload: true });
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_COMMENTING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doConfirm = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_CONFIRMING, payload: true });
    dispatch({ type: actionTypes.SET_IS_CONFIRMED, payload: false });
    const res = await http.get(`admin/transactions/confirm_payment/${fd}`);
    dispatch({ type: actionTypes.SET_CONFIRMING, payload: false });
    dispatch({ type: actionTypes.SET_IS_CONFIRMED, payload: true });
    notifications.success({ title: "Success", msg: res.data.message });
  } catch (err) {
    dispatch({ type: actionTypes.SET_CONFIRMING, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const doReverify =
  (fd, check, selectedTrnxPayout) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.SET_RE_VERIFYING, payload: true });
      dispatch({ type: actionTypes.SET_IS_RE_VERIFIED, payload: false });
      const res = await http.get(
        `admin/transactions/reverify_payment/${fd}/${selectedTrnxPayout}/${check}`
      );
      dispatch({ type: actionTypes.SET_RE_VERIFYING, payload: false });
      dispatch({ type: actionTypes.SET_IS_RE_VERIFIED, payload: true });
      notifications.success({ title: "Success", msg: res.data.message });
    } catch (err) {
      dispatch({ type: actionTypes.SET_RE_VERIFYING, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const getTrnxPayouts = (uuid) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GETTING_TRNX_PAYOUTS, payload: true });
    const res = await http.get(`admin/transactions/select_payout/${uuid}`);
    dispatch({ type: actionTypes.GETTING_TRNX_PAYOUTS, payload: false });
    dispatch({ type: actionTypes.SET_TRNX_PAYOUTS, payload: res.data });
  } catch (err) {
    dispatch({ type: actionTypes.GETTING_TRNX_PAYOUTS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
};

export const exportTransactions =
  (status, startDate, endDate) => async (dispatch) => {
    try {
      dispatch({ type: actionTypes.EXPORTING_DATA, payload: true });
      const res = await http.get(
        `admin/reports/export/transactions?from=${startDate}&to=${endDate}&type=${status}`,
        { responseType: "blob" }
      );

      const contentDisposition = res.headers["content-disposition"];
      const fileName = contentDisposition
        .split(";")
        .find((part) => part.includes("filename"))
        .split("=")[1]
        .replace(/"/g, "");

      saveAs(new Blob([res.data]), fileName);
      dispatch({ type: actionTypes.EXPORTING_DATA, payload: false });
    } catch (err) {
      dispatch({ type: actionTypes.EXPORTING_DATA, payload: false });
      dispatch(error.setErrorMsg(err));
    }
  };

export const adminTransfer = (values) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SET_TRANSFER_LOADING, payload: true });
    console.log(values);
    const res = await http.post(`/admin/transactions/admin_transfer`, values);
    dispatch({ type: actionTypes.SET_TRANSFER_LOADING, payload: false });
    dispatch({ type: actionTypes.SET_TRANSFER_SUCCESS_STATUS, payload: true });
  } catch (err) {
    dispatch({ type: actionTypes.SET_TRANSFER_LOADING, payload: false });
    dispatch({ type: actionTypes.SET_TRANSFER_SUCCESS_STATUS, payload: false });
    dispatch(error.setErrorMsg(err));
  }
  dispatch({ type: actionTypes.SET_SHOW_TRFCONFIRM, payload: false });
  dispatch({ type: actionTypes.SET_SHOW_TRFSTATUS, payload: true });
};

export const fetchTransactionReportsHistory =
  (page = 1) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.LOADING_TRANSACTION_REPORTS,
        payload: true,
      });
      const res = await http.get(
        `/admin/show_xml_reports_downloaded?paginate=1&per_page=10&page=${page}`
      );
      dispatch({
        type: actionTypes.SET_TRANSACTION_REPORTS,
        payload: res.data.data,
      });
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({
        type: actionTypes.LOADING_TRANSACTION_REPORTS,
        payload: false,
      });
    }
  };

export const downloadTransactionHistorybyID = (id) => async (dispatch) => {
  try {
    dispatch({
      type: actionTypes.DOWNLOADING_TRANSACTION_REPORTS,
      payload: true,
    });
    const res = await http.get(`/admin/download_xml_history_report/${id}`, {
      responseType: "blob",
    });

    download(res);
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({
      type: actionTypes.DOWNLOADING_TRANSACTION_REPORTS,
      payload: false,
    });
  }
};
export const exportTransactionsXml =
  (startDate, endDate, handleCloseModal, amountToFetch = 50) =>
  async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.DOWNLOADING_TRANSACTION_REPORTS,
        payload: true,
      });
      const res = await http.get(
        `/admin/xmlexport?export_type=xml&transaction_type=completed&from_date=${startDate}&to_date=${endDate}&fetch=${amountToFetch}`,
        {
          responseType: "blob",
        }
      );
      download(res);
      handleCloseModal();
      dispatch(fetchTransactionReportsHistory());
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({
        type: actionTypes.DOWNLOADING_TRANSACTION_REPORTS,
        payload: false,
      });
    }
  };

export const sequentialExportTransactionsXml =
  (number_of_reports, closeSequentialModal) => async (dispatch) => {
    try {
      dispatch({
        type: actionTypes.DOWNLOADING_TRANSACTION_REPORTS,
        payload: true,
      });
      const res = await http.post(`/admin/download_xml_report?`, {
        number_of_reports,
      });
      download(res);
      dispatch(fetchTransactionReportsHistory());
      closeSequentialModal();
    } catch (err) {
      dispatch(error.setErrorMsg(err));
    } finally {
      dispatch({
        type: actionTypes.DOWNLOADING_TRANSACTION_REPORTS,
        payload: false,
      });
    }
  };
