import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  _completed,
  doComment,
  doVerify,
  doSuspend,
  doRefund,
  doTrash,
  search,
  filterTransaction,
  getTransactionCountries,
} from "../../../store/actions/admin/transactions";
import Pagination from "../../../components/pagination/pagination";
import { Spin, Modal } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import AdminSearchBar from "../../../components/partials/adminSearchBar";
import TimeAgo from "react-timeago";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import FilterSelectWithCount from "../../../components/partials/filterSelectWithCount";
import ExportButton from "../../../components/partials/exportButon";

export default function Completed() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    completed,
    fetchingCompleted,
    suspending,
    verifying,
    trashing,
    commenting,
    refunding,
    isSuspended,
    isVerified,
    isTrashed,
    isCommented,
    isRefunded,
    searchingPending,
    transactionsCountries,
    transactionsCountry,
  } = useSelector((state) => state.adminTransactions);

  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = searchParams.get("page");
  const searchParam = searchParams.get("param");
  const commentForm = useRef(null);

  useEffect(() => {
    if (isVerified) {
      setVerify({ show: false, data: "" });
      dispatch(_completed(currentPage));
    }
    if (isSuspended) {
      setSuspend({ show: false, data: "" });
      dispatch(_completed(currentPage));
    }
    if (isTrashed) {
      setTrash({ show: false, data: "" });
      dispatch(_completed(currentPage));
    }
    if (isRefunded) {
      setRefund({ show: false, data: "" });
      dispatch(_completed(currentPage));
    }
    if (isCommented) {
      setComment({ show: false, data: "" });
      commentForm.current.reset();
      dispatch(_completed(currentPage));
    }
  }, [isSuspended, isVerified, isTrashed, isCommented, isRefunded, dispatch]);

  useEffect(() => {
    if (searchParam) {
      dispatch(
        search({
          param: searchParam,
          page: pageParam,
        })
      );
    } else {
      dispatch(_completed(pageParam ?? 1));
    }
    setCurrentPage(pageParam);
  }, [pageParam, searchParam]);
  useEffect(() => {
    dispatch(getTransactionCountries("completed"));
  }, []);
  const { msg } = useSelector((state) => state.error);

  const [trnx, setTrnx] = useState({ show: false, trnx: {} });
  const [verify, setVerify] = useState({ show: false, data: "" });
  const [suspend, setSuspend] = useState({ show: false, data: "" });
  const [refund, setRefund] = useState({ show: false, data: "" });
  const [trash, setTrash] = useState({ show: false, data: "" });
  const [comment, setComment] = useState({ show: false, data: "" });

  localStorage.setItem("searchFor", "completed");
  const options = transactionsCountries?.map((el) => ({
    value: el.country.id,
    flag: el.country.iso2,
    label: el.country.iso3,
    count: el.country.trx_count,
    id: el.country.id,
  }));
  return (
    <div className="container-fluid">
      <h5 className="fw-bold">Completed Transactions</h5>
      <hr />
      <div className="row mb-4">
        <div className="col-sm-5 mb-3">
          <AdminSearchBar
            callback={search}
            placeholder={"reference number"}
            options={options}
          />{" "}
        </div>
        <div className="col-sm-5 mb-3">
          <FilterSelectWithCount
            options={options}
            callback={filterTransaction}
            placeholder="Filter By Country"
            status="completed"
          />
        </div>
        <div className="col-sm-2 mb-3">
          <div className="px-2 export-container">
            <ExportButton status="completed" />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        {fetchingCompleted || searchingPending ? <Spin /> : ""}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th className="text-muted text-uppercase">#</th>
              <th className="text-muted text-uppercase">Reference</th>
              <th className="text-muted text-uppercase">Sender | Rate </th>
              <th className="text-muted text-uppercase">
                Payin | Payin Reference
              </th>
              <th className="text-muted text-uppercase">Recipient </th>
              <th className="text-muted text-uppercase">Sent at</th>
              <th className="text-muted text-uppercase">Risk Score</th>
              <th className="text-muted text-uppercase">Status</th>
              <th className="text-muted text-uppercase">Action</th>
            </tr>
          </thead>
          <tbody>
            {/* row */}
            {completed.data?.map((el, i) => {
              const verified = el.verify_bank_transfer;
              let status = "warning";
              let time = el.created_at;
              if (verified) {
                status = "success";
                time = el.verify_bank_transfer;
              }
              let count = i + 1;
              if (currentPage > 1) {
                if (count === 10) {
                  count = Number(`${currentPage - 1}${i}`) + 1;
                } else {
                  count = `${currentPage - 1}${count}`;
                }
              }
              return (
                <tr className="border-bottom" key={i}>
                  <td>{count}</td>
                  {/* <i className="fa-sharp fa-solid fa-circle text-danger" /> */}
                  <td>
                    <>
                      {el.kaasi_metas && <span className="text-danger">k</span>}{" "}
                      {el?.reference}{" "}
                    </>
                    <p>{el.payout}</p>
                  </td>
                  <td>
                    <a
                      onClick={() => {
                        // localStorage.setItem("senders_userUuid", el.user.uuid)
                        localStorage.setItem(
                          "senders_profile",
                          JSON.stringify(el.user.profile)
                        );
                        navigate(`/senders/details`, {
                          state: { uuid: el.user.uuid },
                        });
                      }}
                    >
                      {el?.sender_fullname}
                    </a>{" "}
                    <br />
                    <small className="text-muted text-uppercase">
                      {el?.from_country?.currency}
                    </small>
                    {"\u00A0"}
                    <span
                      className={`flag-icon flag-icon-${el?.from_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el?.send_amount} | {el?.rate}
                  </td>
                  <td>
                    {el.payin}
                    <div>
                      <small
                        className={`text-${el?.hold_payin_partner?.partner_name}`}
                      >
                        {el?.hold_payin_partner?.partner_name ?? "-----"}
                      </small>
                      <small> {el?.payin_reference}</small>
                    </div>
                  </td>
                  <td>
                    {`${el?.meta?.beneficiary?.first_name} ${el.meta?.beneficiary?.last_name}`}{" "}
                    <br />
                    <small className="text-muted text-uppercase">
                      {el?.to_country?.currency}
                    </small>
                    {"\u00A0"}
                    <span
                      className={`flag-icon flag-icon-${el?.to_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>
                    {"\u00A0"}
                    {el?.received_amount}
                  </td>

                  <td>{moment(el.created_at).format("YYYY-MM-DD")}</td>
                  <td
                    className={`${el?.risk_type.split("_")[0].toLowerCase()}`}
                  >
                    {el?.risk_score}
                    <br />
                    <small>{el?.risk_type.replace("_", " ")}</small>
                  </td>
                  <td>
                    <small className={`text-${status} text-capitalize`}>
                      {verified ? "Verified" : el.status}
                    </small>
                    <br />
                    <TimeAgo date={time} />
                  </td>
                  <td>
                    <div className="dropdown">
                      <a
                        className="btn dropdown-btn"
                        href="#"
                        data-bs-toggle="dropdown"
                      >
                        <i className="fas fa-ellipsis-v" />
                      </a>
                      <ul className="dropdown-menu shadow-sm rounded">
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#detailsModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setTrnx({ show: true, trnx: el });
                            }}
                          >
                            View
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#addNoteModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setComment({ show: true, data: el.uuid });
                            }}
                          >
                            Comment
                          </a>
                        </li>
                        {/* <li><a className="dropdown-item py-2" href="#" onClick={(e) => { e.preventDefault(); setVerify({ show: true, data: el.uuid }) }}>Verify Payment</a></li>
                                            <li><a className="dropdown-item py-2" href="#" onClick={(e) => { e.preventDefault(); setSuspend({ show: true, data: el.uuid }) }}>Suspend Payment</a></li>
                                            <li><a className="dropdown-item py-2" href="#" onClick={(e) => { e.preventDefault(); setRefund({ show: true, data: el.uuid }) }}>Refund Payment</a></li> */}
                        <li>
                          <a
                            className="dropdown-item py-2"
                            href="#deleteModal"
                            onClick={(e) => {
                              e.preventDefault();
                              setTrash({ show: true, data: el.uuid });
                            }}
                          >
                            Trash
                          </a>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {completed?.links && (
        <Pagination
          onPageChange={(page) => {
            setCurrentPage(page);
            if (completed !== null && completed?.meta?.per_page) {
              if (searchParam) {
                setSearchParams({
                  param: searchParam,
                  page,
                });
                dispatch(
                  search({
                    param: searchParam,
                    page: page,
                  })
                );
              } else {
                setSearchParams({ page });
                dispatch(_completed(page, transactionsCountry));
              }
            }
          }}
          data={completed}
        />
      )}

      {/* trash modal */}
      <Modal
        visible={refund.show}
        footer={false}
        onCancel={() => {
          setRefund({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <p>Are you sure you want to refund this transaction ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setRefund({ show: false, data: {} });
            }}
            disabled={refunding}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={refunding}
            onClick={() => {
              dispatch(doRefund(refund.data));
            }}
          >
            Refund {refunding && <Spin />}
          </button>
        </div>
      </Modal>
      {/* end trash modal */}

      {/* comment modal */}
      <Modal
        visible={comment.show}
        footer={false}
        onCancel={() => {
          setComment({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body">
          <h6 className="fw-bold">Create Note</h6>
          <Formik
            validationSchema={Yup.object({
              title: Yup.string().required("Enter title"),
              comment: Yup.string().required("Enter comment"),
            })}
            initialValues={{
              title: "",
              comment: "",
            }}
            onSubmit={(values) => {
              dispatch(doComment({ ...values, trnxId: comment.data }));
            }}
          >
            {(props) => {
              return (
                <form
                  onSubmit={props.handleSubmit}
                  ref={commentForm}
                  className="py-4"
                >
                  <div className="form-group mb-3">
                    <label for="">Title</label>
                    <input
                      type="text"
                      className="form-control min-height"
                      name="title"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="Title"
                    />
                    {props.touched.title && props.errors.title && (
                      <FormikError msg={props.errors.title} />
                    )}
                    <FormikError msg={msg?.title ?? ""} />
                  </div>

                  <div className="form-group mb-3">
                    <label for="">Body</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      placeholder="Body"
                      name="comment"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    ></textarea>
                    {props.touched.comment && props.errors.comment && (
                      <FormikError msg={props.errors.comment} />
                    )}
                    <FormikError msg={msg?.comment ?? ""} />
                  </div>

                  <div className="text-end">
                    <button
                      className="btn btn-outline-red ms-2 px-4"
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setComment({ show: false, data: {} });
                      }}
                      type="button"
                      disabled={commenting}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-red ms-2 px-4"
                      type="submit"
                      disabled={commenting}
                    >
                      Save {commenting && <Spin />}
                    </button>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </Modal>
      {/* end comment modal */}

      {/* trash modal */}
      <Modal
        visible={trash.show}
        footer={false}
        onCancel={() => {
          setTrash({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <img
            src="../assets/images/components/rafiki.png"
            alt="delete icon"
            className="img-fluid"
          />
          <p>Are you sure you want to trash this transaction ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setTrash({ show: false, data: {} });
            }}
            disabled={trashing}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={trashing}
            onClick={() => {
              dispatch(doTrash(trash.data));
            }}
          >
            Delete {trashing && <Spin />}
          </button>
        </div>
      </Modal>
      {/* end trash modal */}

      {/* suspend modal */}
      <Modal
        visible={suspend.show}
        footer={false}
        onCancel={() => {
          setSuspend({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <p>Are you sure you want to suspend this transaction ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setSuspend({ show: false, data: {} });
            }}
            disabled={suspending}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={suspending}
            onClick={() => {
              dispatch(doSuspend(suspend.data));
            }}
          >
            Suspend {suspending && <Spin />}
          </button>
        </div>
      </Modal>
      {/* end susoend modal */}

      {/* Verify modal */}
      <Modal
        visible={verify.show}
        footer={false}
        onCancel={() => {
          setVerify({ show: false, data: {} });
        }}
      >
        {" "}
        <div className="modal-body text-center">
          <p>Are you sure you want to verify this transaction ?</p>
          <button
            className="btn btn-outline-red mx-2 px-4"
            type="button"
            onClick={() => {
              setVerify({ show: false, data: {} });
            }}
            disabled={verifying}
          >
            Cancel
          </button>
          <button
            className="btn btn-red mx-2 px-4"
            type="button"
            disabled={verifying}
            onClick={() => {
              dispatch(doVerify(verify.data));
            }}
          >
            Verify {verifying && <Spin />}
          </button>
        </div>
      </Modal>
      {/* end suspend modal */}

      {/* view trnx modal */}
      <Modal
        visible={trnx.show}
        footer={false}
        onCancel={() => {
          setTrnx({ show: false, trnx: {} });
        }}
      >
        <h6 className="fw-bold">Transaction Details</h6>

        <div className="py-4">
          <p className="text-muted">Reference: #{trnx.trnx.reference}</p>
          <div className="table-responsive">
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Bank Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Account number</th>
                  <td>{trnx.trnx?.meta?.account.account_number}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank name</th>
                  <td>{trnx.trnx?.bank_name}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank city</th>
                  <td>{trnx.trnx?.bank_city}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Bank country</th>
                  <td>{trnx.trnx?.bank_country}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Recipient details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Name</th>
                  <td>{`${trnx.trnx?.meta?.beneficiary.first_name} ${trnx.trnx?.meta?.beneficiary.last_name}`}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Mobile number</th>
                  <td>{trnx.trnx?.meta?.beneficiary?.phone_no}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Email</th>
                  <td>{trnx.trnx?.meta?.beneficiary.email}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Sender details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>MemberID</th>
                  <td>{trnx.trnx?.sender_memberid}</td>
                </tr>
                <tr className="border-bottom">
                  <th>FullName</th>
                  <td>{trnx.trnx?.sender_fullname}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Mobile number</th>
                  <td>{trnx.trnx?.sender_mobile_no}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Email</th>
                  <td>{trnx.trnx?.sender_email}</td>
                </tr>
                <tr className="border-bottom">
                  <th>ID type</th>
                  <td>{trnx.trnx?.sender_idtype}</td>
                </tr>
                <tr className="border-bottom">
                  <th>ID details</th>
                  <td>{trnx.trnx?.sender_iddetails}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Scanned ID</th>
                  <td>...</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center"
                    colspan="2"
                  >
                    Sent
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Amount</th>
                  <td>
                    {" "}
                    {trnx.trnx?.send_amount} {trnx.trnx?.from_country?.currency}{" "}
                    ({" "}
                    <span
                      className={`flag-icon flag-icon-${trnx.trnx?.from_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>{" "}
                    - {trnx.trnx?.from_country?.name})
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Rate</th>
                  <td>
                    {" "}
                    {trnx.trnx?.rate} ({trnx.trnx?.from_country?.currency} -{" "}
                    {trnx.trnx?.from_country?.name})
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Reason</th>
                  <td>{trnx.trnx?.comment}</td>
                </tr>
              </tbody>
            </table>
            <table className="table table-borderless mb-4">
              <thead>
                <tr>
                  <th
                    className="text-uppercase text-muted text-center text-capitalize"
                    colspan="2"
                  >
                    {trnx.trnx?.status}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-bottom">
                  <th>Amount</th>
                  <td>
                    {" "}
                    {trnx.trnx?.received_amount}{" "}
                    {trnx.trnx?.to_country?.currency} (
                    <span
                      className={`flag-icon flag-icon-${trnx.trnx?.to_country?.iso2.toLowerCase()} flag-icon-squared`}
                    ></span>{" "}
                    - {trnx.trnx.to_country?.name}){" "}
                  </td>
                </tr>
                <tr className="border-bottom">
                  <th>Reference</th>
                  <td>#{trnx.trnx?.reference}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Transfer type</th>
                  <td>{trnx.trnx?.payin}</td>
                </tr>
                <tr className="border-bottom">
                  <th>Transfer message</th>
                  <td>Completed</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="text-end">
            <button
              className="btn btn-red"
              type="button"
              onClick={() => {
                setTrnx({ show: false, trnx: {} });
              }}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
