import * as actionTypes from "../actionTypes/profileActionTypes";
import * as notifications from "../../components/notifications/notifications";
import * as error from "./errorActions";
import { http } from "../../config/axios";
import moment from "moment";
import axios from "axios";
export const savePersonal = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SUBMITTING_PERSONAL });
    const res = await http.post("/profile/store_personal_details", fd);
    dispatch({ type: actionTypes.SUBMITTING_PERSONAL });
    notifications.success({ title: "Success", msg: res.data.message });
    localStorage.setItem("authRes", JSON.stringify(res.data.data));
    error.clearError(dispatch);
    dispatch({
      type: "PROFILE_COMPLETED",
      payload: res.data.data.profile_completed,
    });
    dispatch({
      type: "PERSONAL_COMPLETED",
      payload: res.data.data.profile.personal_details_at,
    });
  } catch (err) {
    dispatch({ type: actionTypes.SUBMITTING_PERSONAL });
    dispatch(error.setErrorMsg(err));
  }
};

export const saveKyc = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SUBMITTING_KYC });
    const res = await http.post("/profile/store_kyc_details", fd);

    localStorage.setItem("authRes", JSON.stringify(res.data.data));

    /** START SHUFTIPRO API */
    // const { profile, idtype, country } = JSON.parse(
    //   localStorage.getItem("authRes")
    // );

    // var payload = {
    //   reference: `SP_REQUEST_${Math.random()}`,
    //   // callback_url: `${process.env.REACT_APP_API_BASE_URL}/http://localhost:3002/`,
    //   callback_url: `https://newx.cosmoremit.com.au/shufti-res`,
    //   redirect_url: `https://newx.cosmoremit.com.au/shufti-res`,
    //   country: country.iso2.toUpperCase(),
    //   language: "EN",
    //   verification_mode: "any",
    // };

    // payload["document"] = {
    //   proof: res.data.data.base_64_front_id_string,
    //   additional_proof: res.data.data.base_64_back_id_string,
    //   name: {
    //     "first_name": profile.firstname,
    //     "last_name ": profile.lastname,
    //     fuzzy_match: "1",
    //   },
    //   dob: moment(profile.dob).format("YYYY-MM-DD"),
    //   age: "",
    //   document_number: profile.id_number,
    //   expiry_date: moment(profile.id_expiry_date).format("YYYY-MM-DD"),
    //   issue_date: moment(profile.id_issue_date).format("YYYY-MM-DD"),
    //   supported_types: ["id_card", "passport"],
    //   gender: profile.gender === "male" ? "M" : "F",
    // };

    // // var token = btoa(
    // //   `${process.env.REACT_APP_SHUFTIPRO_CLIENT_ID}:${process.env.REACT_APP_SHUFTIPRO_SECRET_KEY}`
    // // ); //BASIC AUTH TOKEN
    // let token =
    //   "Y1JaNENMQlZzZ2dYcG9YT0VsekhiVlVHZnZpZHNvT1BzTHNaSFZnQXVZbmRCdnpDSGMxNjYwOTk2MDM1OiQyeSQxMCR5YlMxVTdiUUpsR1J1YzNRSlY4RzRPc2R3WWIzLmFJSDF4bWV1cnRzeE9LZ2FpbGhwaVRNYQ==";
    // axios.defaults.headers = {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    //   Authorization: "Basic " + token, // if access token then replace "Basic" with "Bearer"
    // };

    // const shuftiRes = await axios.post(
    //   "https://api.shuftipro.com/",
    //   JSON.stringify(payload)
    // );

    // console.log(shuftiRes);

    /** END SHUFTIPRO API */
    dispatch({
      type: "KYC_COMPLETED",
      payload: res.data.data.profile.kyc_details_at,
    });
    dispatch(getFacialVerificationStatus());

    notifications.success({ title: "Success", msg: res.data.message });
    dispatch({ type: actionTypes.SUBMITTING_KYC });

    error.clearError(dispatch);
    dispatch({
      type: "PROFILE_COMPLETED",
      payload: res.data.data.profile_completed,
    });
  } catch (err) {
    dispatch({ type: actionTypes.SUBMITTING_KYC });
    dispatch(error.setErrorMsg(err));
  }
};

export const facialVerification = (fd, close) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.LOADING_FACIAL_VERIFICATION, payload: true });
    const formData = new FormData();
    formData.append("profile_photo", fd);

    const res = await http.post("/profile/verify_face", formData);
    dispatch({ type: actionTypes.LOADING_FACIAL_VERIFICATION, payload: false });
    dispatch(getFacialVerificationStatus());
    dispatch(getUserProfile());
    close();
    notifications.success({ title: "Success", msg: res.data.message });
    error.clearError(dispatch);
  } catch (err) {
    dispatch({ type: actionTypes.LOADING_FACIAL_VERIFICATION, payload: false });

    dispatch(error.setErrorMsg(err));
  }
};

export const saveAddress = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SUBMITTING_ADDRESS });
    const res = await http.post("/profile/store_address_details", fd);
    dispatch({ type: actionTypes.SUBMITTING_ADDRESS });
    notifications.success({ title: "Success", msg: res.data.message });
    localStorage.setItem("authRes", JSON.stringify(res.data.data));
    error.clearError(dispatch);
    dispatch({
      type: "PROFILE_COMPLETED",
      payload: res.data.data.profile_completed,
    });
    dispatch({
      type: "ADDRESS_COMPLETED",
      payload: res.data.data.profile.address_details_at,
    });
  } catch (err) {
    dispatch({ type: actionTypes.SUBMITTING_ADDRESS });
    dispatch(error.setErrorMsg(err));
  }
};
export const saveOutbound = (fd) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.SUBMITTING_OUTBOUND });
    const res = await http.post("/profile/save_outbound_countries", fd);
    dispatch({ type: actionTypes.SUBMITTING_OUTBOUND });
    notifications.success({ title: "Success", msg: res.data.message });
    dispatch(getUserProfile());
    error.clearError(dispatch);
  } catch (err) {
    dispatch({ type: actionTypes.SUBMITTING_OUTBOUND });
    dispatch(error.setErrorMsg(err));
  }
};

export const getUserOutbound = () => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GETTING_OUTBOUND_DATA });
    const res = await http.get("/profile/get_user_outbounds");
    dispatch({ type: actionTypes.GETTING_OUTBOUND_DATA });
    dispatch({
      type: actionTypes.SET_OUTBOUND_DATA,
      payload: res.data.data.data,
    });
    error.clearError(dispatch);
  } catch (err) {
    dispatch({ type: actionTypes.SUBMITTING_OUTBOUND });
    dispatch(error.setErrorMsg(err));
  }
};

export const getFacialVerificationStatus = () => async (dispatch) => {
  try {
    const res = await http.get("/profile/check_face_verified");

    dispatch({
      type: actionTypes.SET_FACIAL_VERIFICATION_STATUS,
      payload: res.data.data.verified,
    });

    error.clearError(dispatch);
  } catch (err) {
    dispatch({ type: actionTypes.GETTING_OUTBOUND_DATA });

    dispatch(error.setErrorMsg(err));
  }
};

export const getUserProfile = (fetchSuccess) => async (dispatch) => {
  try {
    dispatch({ type: actionTypes.GETTING_PROFILE_DATA, payload: true });
    const res = await http.get("/profile/detail");
    localStorage.setItem("authRes", JSON.stringify(res.data.data));
    dispatch({
      type: "UPDATE_USER",
    });
    dispatch({
      type: "CAN_TRANSACT",
      payload: res.data.data.can_transact,
    });
    error.clearError(dispatch);
    if (fetchSuccess) {
      fetchSuccess();
    }
  } catch (err) {
    dispatch(error.setErrorMsg(err));
  } finally {
    dispatch({ type: actionTypes.GETTING_PROFILE_DATA, payload: false });
  }
};
