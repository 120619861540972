import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Select } from "antd";
import { useNavigate } from "react-router-dom";
import FormikError from "../formikError";
import * as notifications from "../../notifications/notifications";
import { MdOutlinePersonAddAlt } from "react-icons/md";
import AddBeneficiaryForm from "../beneficiaryComponents/addBeneficiaryForm";
import { useMoneyTransfer } from "../../../hooks/send.hooks";
import { getPopupContainer } from "../../../store/actions/utilityActions";
import {
  checkBeneficiaryAddressStatus,
  fetchBeneficiaries,
  setBeneficiaryToEdit,
  toggleEditModal,
} from "../../../store/actions/beneficiaryActions";
import AddBeneficiaryComponent from "./addBenefeciaryComponent";
import KassiErrorModal from "../modals/KassiErrorModal";
import ModalConponent from "../../modal-component/modalConponent";
import { useFetchBusinessData } from "../../../hooks/fetchBusinessData";
import { http } from "../../../config/axios";
import ReactMarkdown from "react-markdown";

const TransferMoney = (props) => {
  const [transact_as, setTransact_As] = useState("profiles");
  const [showBeneficiaryModal, setShowBeneficiaryModal] = useState(false);
  const [toggleOff, setToggleOff] = useState(true);
  const [tempuaryShowContent, setTempuaryShowContent] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [beneficairyUuid, setBeneficairyUuid] = useState("");
  const [filterBeneficiary, setFilterBeneficiary] = useState([]);
  const [walletUuid, setWalletUuid] = useState("");
  const [receiver, _setReceiver] = useState({});
  const [transactionType, setTransactionType] = useState("");
  const [kassiErrModal, setKassiErrorModal] = useState({
    visible: false,
    uuid: "",
  });
  const { beneficiaries } = useSelector((state) => state.beneficiaryIndex);
  const { beneficiaryAddressStatus, checkingAddressStatus } = useSelector(
    (state) => state.editBeneficiary
  );
  const { businessData, loading: loadingBusinessData } = useFetchBusinessData();
  const [showNotification, setShowNotification] = useState(false);
  const { user_wallets, canUseWallet } = useSelector((state) => state.wallet);
  const profile = JSON.parse(localStorage.getItem("authRes"));

  const user = JSON.parse(localStorage.getItem("authRes")) ?? {};

  let popMessages = JSON.parse(localStorage.getItem("popMessages"));

  const AlertPopMessages = (callback) => {
    if (user?.pop_messages?.length > 0) {
      let type = user?.pop_messages[0]?.type;
      let msg = user?.pop_messages[0]?.message;
      let title = user?.pop_messages[0]?.title;

      const closePop = () => {
        const newPops = popMessages.filter(
          (el) => Number(el.id) !== Number(user?.pop_messages[0].id)
        );
        localStorage.setItem("popMessages", JSON.stringify(newPops));
        try {
          setShowNotification(!showNotification);
          http.post("settings/pop_message_read", {
            pop_id: user?.pop_messages[0].id,
          });
        } catch (err) {}
        if (callback) callback();
      };

      const content = <ReactMarkdown>{msg}</ReactMarkdown>;

      const modalConfig = {
        title: title,
        content: content,
        okText: "I have read & agreed",
        onOk: () => closePop(),
        width: 600,
        closable: false,
        maskClosable: false,
        bodyStyle: { textAlign: "left" },
        style: { top: "20%" },
      };

      switch (type) {
        case "warning":
          Modal.warning(modalConfig);
          break;
        case "info":
          Modal.info(modalConfig);
          break;
        case "success":
          Modal.success(modalConfig);
          break;
        case "danger":
          Modal.error(modalConfig);
          break;
        default:
          break;
      }

      if (msg) {
        console.log(msg);
      }
    } else {
      // If no pop-up messages, proceed directly
      if (callback) callback();
    }
  };

  const handleTransactAsChange = (e) => {
    setTransact_As("business");
  };

  const handleToggleOffModal = () => {
    if (toggleOff) {
      setShowBeneficiaryModal(!showBeneficiaryModal);
    }
  };

  const toggleBeneficiaryModal = (e) => {
    if (profile.can_transact) {
      setShowBeneficiaryModal(!showBeneficiaryModal);
    } else {
      notifications.warning({
        title: "Warning",
        msg: `Dear customer, 

        Thank you for choosing us,
        
        Please be informed that your profile has not been fully verified 
        
        
        Kindly contact the team at <span class="text-red">compliance@cosmoremit.com.au</span> to activate your profile 
        
        Thank you`,
      });
    }
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authRes = JSON.parse(localStorage.getItem("authRes"));
  const send_option = sessionStorage.getItem("send_option");

  const {
    sendVal,
    inputChangeHandler,
    fromCountryIso,
    fromCountryCurency,
    fromSearch,
    fromCountryListFilter,
    setFromCountryIso,
    setFromCountryCurrency,
    countryFromChange,
    receiveVal,
    toCountryIso,
    toCurrency,
    fromCurrency,
    toSearch,
    toCountryListFilter,
    setToCountryIso,
    setToCurrency,
    countryToChange,
    rate,
    fee,
    err,
    setFromCountry,
    payinChange,
    selectedPayin,
    payins,
    eta,
    fromCountry,
    toCountry,
    selectedPayinName,
    selectedPayinUUID,
    setSelectedPayin,
    setSelectedPayinName,
    currency_wallets,
    fetchingTrxData,
  } = useMoneyTransfer();

  const selectedBeneficiary = (uuid) => {
    const selected = filterBeneficiary.find(
      (beneficiary) => beneficiary.beneficiary_uuid === uuid
    );

    return selected; // Return the selected beneficiary if you need it in the calling code
  };
  const beneficiarySearch = (val) => {
    let ben = beneficiaries?.data;

    if (!val) {
      setFilterBeneficiary(ben);
      return;
    }
    ben = ben?.filter((el) =>
      el.fullname.toLowerCase().includes(val.toLowerCase())
    );
    setFilterBeneficiary(ben);
  };
  const setWalletValues = (checkWalletPayin) => {
    setTransactionType("wallet");
    setSelectedPayinName(`${checkWalletPayin?.country?.currency} wallet`);
    localStorage.setItem("trx_type", "wallet");
  };
  useEffect(() => {
    const checkWalletPayin = selectedWallet(selectedPayin);
    if (checkWalletPayin) {
      setWalletValues(checkWalletPayin);
    } else {
      setTransactionType("");
      localStorage.setItem("trx_type", "");
    }
  }, [selectedPayin]);

  useEffect(() => {
    if (beneficiaries?.data?.length > 0) {
      setFilterBeneficiary([...beneficiaries.data]);
    }
  }, [beneficiaries]);

  const setReceiver = (el) => {
    dispatch({ type: "SET_ERR", payload: "" });
    _setReceiver(el);
    localStorage.setItem("receiver", JSON.stringify(el));
  };

  const selectedWallet = (payin_id) => {
    const selected = user_wallets.find(
      (wallet) => wallet.payin_id === payin_id
    );
    return selected; // Return the selected beneficiary if you need it in the calling code
  };

  const checkBeneficiaryCountryId = (beneficiary_country_id) => {
    const wallet = currency_wallets.find(
      (currency) => currency.country.id === beneficiary_country_id
    );

    return wallet;
  };

  const handleBeneficiaryChange = async (e) => {
    const selected = selectedBeneficiary(e.value);
    dispatch(checkBeneficiaryAddressStatus(e.value));

    setReceiver(selected);
    setToCountryIso(selected?.iso2);
    setToCurrency(selected?.currency);
    countryToChange(selected?.country_id);
    setBeneficairyUuid(e.value);
  };
  useEffect(() => {
    const fromCountry = localStorage.getItem("FROM_COUNTRY");
    if (props?.receiver && Object.keys(props?.receiver)?.length !== 0) {
      setToCountryIso(props?.receiver?.iso2);
      setToCurrency(props?.receiver?.currency);
      countryToChange(props?.receiver?.country_id, fromCountry);
      setBeneficairyUuid(props?.receiver?.beneficiary_uuid);
      setReceiver(props?.receiver);
    }
  }, [props?.receiver]);

  useEffect(() => {
    dispatch(fetchBeneficiaries());
    dispatch({ type: "SET_ERR", payload: "" });
  }, []);

  const handleRersize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  useEffect(() => {
    window.addEventListener("resize", handleRersize);
    return () => {
      window.removeEventListener("resize", handleRersize);
    };

    //
  }, []);

  useEffect(() => {
    setTempuaryShowContent(!showBeneficiaryModal);
  }, [showBeneficiaryModal]);

  useEffect(() => {
    setTempuaryShowContent(!isMobile || !showBeneficiaryModal);
  }, [isMobile]);

  return (
    <>
      <div className="wrap____relative">
        {/** remove*/}

        {tempuaryShowContent ? (
          <form action="auth/login.html" className="py-4">
            <FormikError msg={err ?? ""} />

            {profile?.has_business_account &&
              businessData?.business_verify_status === "verified" && (
                <div className="form-floating mb-3 beneficiary-select-container d-flex ">
                  <Select
                    value={transact_as}
                    name="transact_as"
                    id="transact_as"
                    className="form-select beneficiary-select"
                    labelInValue
                    placeholder="Transfer as"
                    optionFilterProp="children"
                    onChange={handleTransactAsChange}
                  >
                    <Select.Option value="profiles">
                      Personal Account
                    </Select.Option>

                    <Select.Option value="business">
                      Business Account
                    </Select.Option>
                  </Select>
                  <label htmlFor="transact_as">Transfer with</label>
                </div>
              )}

            <div className="form-floating mb-3 beneficiary-select-container d-flex ">
              {/* payment method */}
              <Select
                value={selectedPayin}
                name="payin"
                id="paymentMethod"
                className="form-select beneficiary-select"
                placeholder="Select a Payin"
                onChange={(e) => {
                  const checkType = selectedWallet(e);
                  if (checkType) {
                    setWalletUuid(checkType.uuid);
                    setSelectedPayin(checkType.payin_id);
                    setFromCountry(checkType.country.id);
                    setFromCountryCurrency(checkType.country.currency);
                    setFromCountryIso(checkType.country.iso2);
                    countryFromChange(checkType.country.id);
                    localStorage.setItem("FROM_COUNTRY", checkType.country?.id);
                    dispatch({
                      type: "SET_SENDING_CURRENCY",
                      payload: checkType.country?.currency,
                    });
                  } else {
                    setWalletUuid("");
                    localStorage.setItem("trx_type", "");
                    payinChange(e);
                  }
                }}
                getPopupContainer={getPopupContainer}
              >
                {payins?.map((el, i) => (
                  <Select.Option key={el.id} value={el.id} payin={el.name}>
                    {el.name}
                  </Select.Option>
                ))}

                {canUseWallet &&
                  user_wallets?.map((el, i) => (
                    <Select.Option
                      key={el.uuid}
                      value={el.payin_id}
                      payin={el.currency}
                    >
                      {el.country.currency} wallet (Balance: {el.balance}
                      {el.country.currency})
                    </Select.Option>
                  ))}
              </Select>
              <label htmlFor="paymentMethod">Payment Method</label>
            </div>
            {/* you send */}
            <div className="mb-4">
              <div className="input-group floating-group mb-2">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control rounded-end-0"
                    id="youSend"
                    placeholder={0}
                    value={sendVal}
                    name="up"
                    onChange={inputChangeHandler}
                  />
                  <label htmlFor="youSend">You send</label>
                </div>{" "}
                <div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
                  <button
                    className={`btn form-control border-0 end  text-start  ${
                      !transactionType && "dropdown-toggle"
                    }`}
                    type="button"
                    data-bs-toggle={`${!transactionType && "dropdown"}`}
                    aria-expanded="false"
                  >
                    <span
                      className={`flag-icon flag-icon-${fromCountryIso?.toLowerCase()} flag-icon-squared`}
                    ></span>
                    &nbsp;
                    {fromCountryCurency}
                  </button>

                  <ul className="dropdown-menu p-3 position-absolute  country-dropdown country-dropdown-small shadow">
                    <li>
                      <div className="input-group mb-2">
                        <span className="input-group-text text-muted">
                          <i className="fas fa-search" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => {
                            fromSearch(e.target.value);
                          }}
                          className="form-control border-start-0 min-height"
                        />
                      </div>
                    </li>
                    {fromCountryListFilter?.map((el, i) => {
                      return (
                        <li key={i}>
                          <button
                            className="dropdown-item py-3 pe-5"
                            style={{ cursor: "pointer" }}
                            type="button"
                            onClick={() => {
                              setFromCountryIso(el.iso2);
                              setFromCountryCurrency(el.currency);
                              countryFromChange(el.id);
                              localStorage.setItem("FROM_COUNTRY", el?.id);
                              dispatch({
                                type: "SET_SENDING_CURRENCY",
                                payload: el?.currency,
                              });
                            }}
                          >
                            <span
                              className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                            ></span>
                            &nbsp;
                            {el.currency}{" "}
                            <small className="text-muted">{el.name}</small>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {send_option === "multiple" && (
              <>
                <div className="d-flex  justify-content-end">
                  <button
                    type="button"
                    onClick={toggleBeneficiaryModal}
                    className="border-0 bg-white fw-bold text-red d-flex align-items-center gap-2 justify-content-end "
                  >
                    <MdOutlinePersonAddAlt /> New beneficiary
                  </button>
                </div>
                {/* Select beneficiary */}
                <div className="form-floating mb-3 beneficiary-select-container d-flex ">
                  <Select
                    value={beneficairyUuid}
                    name="beneficiary"
                    id="beneficiary"
                    className="form-select beneficiary-select"
                    showSearch
                    labelInValue
                    placeholder="Select a beneficiary"
                    optionFilterProp="children"
                    onChange={handleBeneficiaryChange}
                    onSearch={beneficiarySearch}
                    getPopupContainer={getPopupContainer}
                  >
                    <Select.Option value="">Select Beneficiary</Select.Option>
                    {filterBeneficiary?.map((beneficiary) => {
                      if (!transactionType) {
                        return (
                          <Select.Option
                            key={beneficiary.beneficiary_uuid}
                            value={beneficiary.beneficiary_uuid}
                          >
                            {beneficiary.fullname}
                          </Select.Option>
                        );
                      } else {
                        if (checkBeneficiaryCountryId(beneficiary.country_id)) {
                          return (
                            <Select.Option
                              key={beneficiary.beneficiary_uuid}
                              value={beneficiary.beneficiary_uuid}
                            >
                              {beneficiary.fullname}
                            </Select.Option>
                          );
                        }
                      }
                    })}
                  </Select>
                  <label htmlFor="beneficiary">Beneficiary</label>
                </div>
              </>
            )}

            {/* Reciepient Gets */}
            <div className="input-group position-relative mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-end-0"
                  id="reciepientGets"
                  placeholder={0}
                  value={receiveVal}
                  onChange={inputChangeHandler}
                  name="down"
                />
                <label htmlFor="reciepientGets">Reciepient Gets</label>
              </div>

              <div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
                <button
                  className="btn form-control border-0 end  text-start dropdown-toggle"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle={`dropdown`}
                >
                  <span
                    className={`flag-icon flag-icon-${toCountryIso?.toLowerCase()} flag-icon-squared`}
                  ></span>
                  &nbsp;
                  {toCurrency}
                </button>

                <ul className="dropdown-menu p-3 position-absolute  country-dropdown country-dropdown-small shadow">
                  <li>
                    <div className="input-group mb-2">
                      <span className="input-group-text text-muted">
                        <i className="fas fa-search" />
                      </span>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          toSearch(e.target.value);
                        }}
                        className="form-control border-start-0 min-height"
                      />
                    </div>
                  </li>
                  {toCountryListFilter?.map((el, i) => {
                    return (
                      <li key={i}>
                        <button
                          className="dropdown-item py-3 pe-5"
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setToCountryIso(el?.iso2);
                            setToCurrency(el?.currency);
                            countryToChange(el?.id);
                            localStorage.setItem("TO_COUNTRY", el?.id);
                            dispatch({
                              type: "SET_RECEIVING_CURRENCY",
                              payload: el?.currency,
                            });
                          }}
                        >
                          <span
                            className={`flag-icon flag-icon-${el?.iso2?.toLowerCase()} flag-icon-squared`}
                          ></span>
                          &nbsp;
                          {el.currency}{" "}
                          <small className="text-muted">{el?.name}</small>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* conversion details */}
            <ul className="list-unstyled hero-card-details my-3 swiper_container">
              <li className="d-flex px-4 justify-content-between py-2">
                <small className="text-muted">Rate</small>
                <span>
                  {typeof fromCurrency !== "undefined" && (
                    <span>
                      1 {fromCountryCurency} = {rate ?? 0} {toCurrency}
                    </span>
                  )}
                </span>
              </li>

              {/* fee */}
              <li className="d-flex px-4 justify-content-between py-2">
                <small className="text-muted">Fee</small>
                <span>
                  {fee} {fromCountryCurency}
                </span>
              </li>

              {/* Recipient gets */}
              {/* <li className="d-flex px-4 justify-content-between py-2">
            <small className="text-muted">Recipient gets</small>
            <span>
                <span>
                  {receiveVal} {toCurrency}
                </span>
            </span>
          </li> */}
            </ul>
            <button
              type="submit"
              onClick={(e) => {
                console.log(err);
                e.preventDefault();
                if (err) return;
                if (beneficiaryAddressStatus) {
                  setKassiErrorModal({
                    visible: true,
                    uuid: beneficairyUuid,
                  });

                  return;
                }

                localStorage.setItem("trx_sendVal", sendVal);
                localStorage.setItem("trx_receiveVal", receiveVal);
                localStorage.setItem("trx_fee", fee);
                localStorage.setItem("trx_eta", eta);
                localStorage.setItem("trx_rate", rate);
                localStorage.setItem("trx_fromCountry", fromCountry);
                localStorage.setItem(
                  "trx_fromCountryCurency",
                  fromCountryCurency
                );
                localStorage.setItem("trx_toCountry", toCountry);
                localStorage.setItem("trx_selected_wallet_uuid", walletUuid);
                localStorage.setItem("trx_selectedPayin", selectedPayin);
                localStorage.setItem("trx_toCurrency", toCurrency);
                localStorage.setItem(
                  "trx_selectedPayinName",
                  selectedPayinName
                );
                localStorage.setItem(
                  "trx_selectedPayinUUID",
                  selectedPayinUUID
                );
                localStorage.setItem("trx_fromCountryIso", fromCountryIso);
                localStorage.setItem("trx_toCountryIso", toCountryIso);
                localStorage.setItem("transact_as", transact_as);

                // if this component is called from landing page
                if (props.page === "landing") {
                  if (!authRes) {
                    /* if it this component is called from landing page and not logged in 
                  redirect to login page although the inputed data is saved in local storage
                */
                    navigate("/login", {
                      state: { calledFrom: "send_money_on_landing_page" },
                    });
                  }
                }

                // if this component is called from user dashboard

                if (!profile.can_transact) {
                  notifications.warning({
                    title: "Warning",
                    msg: `Dear customer, 
  
                  Thank you for choosing us,
                  
                  Please be informed that your profile has not been fully verified 
                  
                  
                  Kindly contact the team at <span class="text-red">compliance@cosmoremit.com.au</span> to activate your profile 
                  
                  Thank you`,
                  });
                  return;
                }
                dispatch({ type: "SET_ERR", payload: "" });

                if (Object.keys(receiver)?.length !== 0) {
                } else {
                  // if no receiver was selected
                  dispatch({ type: "SET_ERR", payload: "Select a recepient" });
                  return;
                }
                // if (fetchingInputRecepientAmount === true || fetchedInputFromAmount === true){
                //   dispatch({ type: "SET_ERR", payload: "Please Wait" });
                //   return
                // }

                if (user) {
                  AlertPopMessages();
                }
                navigate("/complete-transaction");
              }}
              className="btn min-height btn-red w-100"
              disabled={!process.env.REACT_APP_ENABLE_SEND || fetchingTrxData}
            >
              {fetchingTrxData ? "Loading..." : "Send"}
            </button>
          </form>
        ) : // null
        !isMobile && showBeneficiaryModal ? (
          <form action="auth/login.html" className="py-4">
            <FormikError msg={err ?? ""} />

            <div className="form-floating mb-3 beneficiary-select-container d-flex ">
              {/* payment method */}
              <Select
                value={selectedPayin}
                name="payin"
                id="paymentMethod"
                className="form-select beneficiary-select"
                placeholder="Select a Payin"
                onChange={(e) => {
                  const checkType = selectedWallet(e);
                  if (checkType) {
                    setWalletUuid(checkType.uuid);
                    setSelectedPayin(checkType.payin_id);
                    setFromCountry(checkType.country.id);
                    setFromCountryCurrency(checkType.country.currency);
                    setFromCountryIso(checkType.country.iso2);
                    countryFromChange(checkType.country.id);
                    localStorage.setItem("FROM_COUNTRY", checkType.country?.id);
                    dispatch({
                      type: "SET_SENDING_CURRENCY",
                      payload: checkType.country?.currency,
                    });
                  } else {
                    setWalletUuid("");
                    localStorage.setItem("trx_type", "");
                    payinChange(e);
                  }
                }}
                getPopupContainer={getPopupContainer}
              >
                {payins?.map((el, i) => (
                  <Select.Option key={el.id} value={el.id} payin={el.name}>
                    {el.name}
                  </Select.Option>
                ))}

                {canUseWallet &&
                  user_wallets?.map((el, i) => (
                    <Select.Option
                      key={el.uuid}
                      value={el.payin_id}
                      payin={el.currency}
                    >
                      {el.country.currency} wallet (Balance: {el.balance}
                      {el.country.currency})
                    </Select.Option>
                  ))}
              </Select>
              <label htmlFor="paymentMethod">Payment Method</label>
            </div>

            {/* you send */}
            <div className="mb-4">
              <div className="input-group floating-group mb-2">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control rounded-end-0"
                    id="youSend"
                    placeholder={0}
                    value={sendVal}
                    name="up"
                    onChange={inputChangeHandler}
                  />
                  <label htmlFor="youSend">You send</label>
                </div>{" "}
                <div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
                  <button
                    className={`btn form-control border-0 end  text-start  ${
                      !transactionType && "dropdown-toggle"
                    }`}
                    type="button"
                    data-bs-toggle={`${!transactionType && "dropdown"}`}
                    aria-expanded="false"
                  >
                    <span
                      className={`flag-icon flag-icon-${fromCountryIso?.toLowerCase()} flag-icon-squared`}
                    ></span>
                    &nbsp;
                    {fromCountryCurency}
                  </button>

                  <ul className="dropdown-menu p-3 position-absolute  country-dropdown country-dropdown-small shadow">
                    <li>
                      <div className="input-group mb-2">
                        <span className="input-group-text text-muted">
                          <i className="fas fa-search" />
                        </span>
                        <input
                          type="text"
                          placeholder="Search"
                          onChange={(e) => {
                            fromSearch(e.target.value);
                          }}
                          className="form-control border-start-0 min-height"
                        />
                      </div>
                    </li>
                    {fromCountryListFilter?.map((el, i) => {
                      return (
                        <li key={i}>
                          <button
                            className="dropdown-item py-3 pe-5"
                            style={{ cursor: "pointer" }}
                            type="button"
                            onClick={() => {
                              setFromCountryIso(el.iso2);
                              setFromCountryCurrency(el.currency);
                              countryFromChange(el.id);
                              localStorage.setItem("FROM_COUNTRY", el?.id);
                              dispatch({
                                type: "SET_SENDING_CURRENCY",
                                payload: el?.currency,
                              });
                            }}
                          >
                            <span
                              className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                            ></span>
                            &nbsp;
                            {el.currency}{" "}
                            <small className="text-muted">{el.name}</small>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {send_option === "multiple" && (
              <>
                <div className="d-flex  justify-content-end">
                  <button
                    type="button"
                    onClick={toggleBeneficiaryModal}
                    className="border-0 bg-white fw-bold text-red d-flex align-items-center gap-2 justify-content-end "
                  >
                    <MdOutlinePersonAddAlt /> New beneficiary
                  </button>
                </div>
                {/* Select beneficiary */}
                <div className="form-floating mb-3 beneficiary-select-container d-flex ">
                  <Select
                    value={beneficairyUuid}
                    name="beneficiary"
                    id="beneficiary"
                    className="form-select beneficiary-select"
                    showSearch
                    labelInValue
                    placeholder="Select a beneficiary"
                    optionFilterProp="children"
                    onChange={handleBeneficiaryChange}
                    onSearch={beneficiarySearch}
                    getPopupContainer={getPopupContainer}
                  >
                    <Select.Option value="">Select Beneficiary</Select.Option>
                    {filterBeneficiary?.map((beneficiary) => {
                      if (!transactionType) {
                        return (
                          <Select.Option
                            key={beneficiary.beneficiary_uuid}
                            value={beneficiary.beneficiary_uuid}
                          >
                            {beneficiary.fullname}
                          </Select.Option>
                        );
                      } else {
                        if (checkBeneficiaryCountryId(beneficiary.country_id)) {
                          return (
                            <Select.Option
                              key={beneficiary.beneficiary_uuid}
                              value={beneficiary.beneficiary_uuid}
                            >
                              {beneficiary.fullname}
                            </Select.Option>
                          );
                        }
                      }
                    })}
                  </Select>
                  <label htmlFor="beneficiary">Beneficiary</label>
                </div>
              </>
            )}

            {/* Reciepient Gets */}
            <div className="input-group position-relative mb-3">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control rounded-end-0"
                  id="reciepientGets"
                  placeholder={0}
                  value={receiveVal}
                  onChange={inputChangeHandler}
                  name="down"
                />
                <label htmlFor="reciepientGets">Reciepient Gets</label>
              </div>

              <div className="dropdown position-relative input-group-text end bg-white d-inline-block dropdown-select">
                <button
                  className="btn form-control border-0 end  text-start dropdown-toggle"
                  type="button"
                  aria-expanded="false"
                  data-bs-toggle={`dropdown`}
                >
                  <span
                    className={`flag-icon flag-icon-${toCountryIso?.toLowerCase()} flag-icon-squared`}
                  ></span>
                  &nbsp;
                  {toCurrency}
                </button>

                <ul className="dropdown-menu p-3 position-absolute  country-dropdown country-dropdown-small shadow">
                  <li>
                    <div className="input-group mb-2">
                      <span className="input-group-text text-muted">
                        <i className="fas fa-search" />
                      </span>
                      <input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => {
                          toSearch(e.target.value);
                        }}
                        className="form-control border-start-0 min-height"
                      />
                    </div>
                  </li>
                  {toCountryListFilter?.map((el, i) => {
                    return (
                      <li key={i}>
                        <button
                          className="dropdown-item py-3 pe-5"
                          type="button"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setToCountryIso(el?.iso2);
                            setToCurrency(el?.currency);
                            countryToChange(el?.id);
                            localStorage.setItem("TO_COUNTRY", el?.id);
                            dispatch({
                              type: "SET_RECEIVING_CURRENCY",
                              payload: el?.currency,
                            });
                          }}
                        >
                          <span
                            className={`flag-icon flag-icon-${el?.iso2?.toLowerCase()} flag-icon-squared`}
                          ></span>
                          &nbsp;
                          {el.currency}{" "}
                          <small className="text-muted">{el?.name}</small>
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            {/* conversion details */}
            <ul className="list-unstyled hero-card-details my-3 swiper_container">
              <li className="d-flex px-4 justify-content-between py-2">
                <small className="text-muted">Rate</small>
                <span>
                  {typeof fromCurrency !== "undefined" && (
                    <span>
                      1 {fromCountryCurency} = {rate ?? 0} {toCurrency}
                    </span>
                  )}
                </span>
              </li>

              {/* fee */}
              <li className="d-flex px-4 justify-content-between py-2">
                <small className="text-muted">Fee</small>
                <span>
                  {fee} {fromCountryCurency}
                </span>
              </li>
            </ul>
            <button
              type="submit"
              onClick={(e) => {
                console.log(err);
                e.preventDefault();
                if (err) return;
                if (beneficiaryAddressStatus) {
                  setKassiErrorModal({
                    visible: true,
                    uuid: beneficairyUuid,
                  });

                  return;
                }
                localStorage.setItem("trx_sendVal", sendVal);
                localStorage.setItem("trx_receiveVal", receiveVal);
                localStorage.setItem("trx_fee", fee);
                localStorage.setItem("trx_eta", eta);
                localStorage.setItem("trx_rate", rate);
                localStorage.setItem("trx_fromCountry", fromCountry);
                localStorage.setItem(
                  "trx_fromCountryCurency",
                  fromCountryCurency
                );
                localStorage.setItem("trx_toCountry", toCountry);
                localStorage.setItem("trx_selected_wallet_uuid", walletUuid);
                localStorage.setItem("trx_selectedPayin", selectedPayin);
                localStorage.setItem("trx_toCurrency", toCurrency);
                localStorage.setItem(
                  "trx_selectedPayinName",
                  selectedPayinName
                );
                localStorage.setItem(
                  "trx_selectedPayinUUID",
                  selectedPayinUUID
                );
                localStorage.setItem("trx_fromCountryIso", fromCountryIso);
                localStorage.setItem("trx_toCountryIso", toCountryIso);

                // if this component is called from landing page
                if (props.page === "landing") {
                  if (!authRes) {
                    /* if it this component is called from landing page and not logged in 
                  redirect to login page although the inputed data is saved in local storage
                */
                    navigate("/login", {
                      state: { calledFrom: "send_money_on_landing_page" },
                    });
                  }
                }

                // if this component is called from user dashboard

                if (!profile.can_transact) {
                  notifications.warning({
                    title: "Warning",
                    msg: `Dear customer, 
  
                  Thank you for choosing us,
                  
                  Please be informed that your profile has not been fully verified 
                  
                  
                  Kindly contact the team at <span class="text-red">compliance@cosmoremit.com.au</span> to activate your profile 
                  
                  Thank you`,
                  });
                  return;
                }
                dispatch({ type: "SET_ERR", payload: "" });

                if (Object.keys(receiver)?.length !== 0) {
                } else {
                  // if no receiver was selected
                  dispatch({ type: "SET_ERR", payload: "Select a recepient" });
                  return;
                }

                if (user) {
                  AlertPopMessages();
                }

                navigate("/complete-transaction");
              }}
              className="btn min-height btn-red w-100"
              disabled={
                !process.env.REACT_APP_ENABLE_SEND ||
                fetchingTrxData ||
                checkingAddressStatus
              }
            >
              {fetchingTrxData || checkingAddressStatus ? "Loading..." : "Send"}
            </button>
          </form>
        ) : null}
        {isMobile && showBeneficiaryModal ? (
          <AddBeneficiaryComponent
            showBeneficiaryModal={showBeneficiaryModal}
            toggleBeneficiaryModal={toggleBeneficiaryModal}
            content={<AddBeneficiaryForm />}
          />
        ) : (
          // <Modal
          // 	className="modal_mobile"
          // 	visible={showBeneficiaryModal}
          // 	onCancel={toggleBeneficiaryModal}
          // 	footer={false}
          // >
          // 	<div className="d-flex">
          // 		<AddBeneficiaryForm />
          // 	</div>
          // </Modal>

          <ModalConponent
            show={showBeneficiaryModal}
            onClose={toggleBeneficiaryModal}
            body_clickOff={handleToggleOffModal}
            children={<AddBeneficiaryForm />}
          />
        )}
        <KassiErrorModal
          visible={kassiErrModal.visible}
          onClose={() => {
            setKassiErrorModal({ visible: false, uuid: "" });
          }}
          uuid={kassiErrModal.uuid}
        />
      </div>
    </>
  );
};
export default TransferMoney;
