import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import FormikError from "../../../components/partials/formikError";
import { Spin, Modal } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { intializeTrx } from "../../../store/actions/transaction";
import poli from "../../../assets/images/icon/poliMark.jpg";
import { load } from "cheerio";
import DOMPurify from "dompurify";
import TermsModal from "../../../components/partials/termsModal";
import Successful from "../../../components/partials/walletComponents/modals/success/Successful";
import TransactionReceiptNew from "../../admin/transaction/transactionReceiptNew";

export default function Complete() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const receiver = JSON.parse(localStorage.getItem("receiver")) ?? {};
	const trxMetas = JSON.parse(localStorage.getItem("checkoutMetas"));
	const authRes = JSON.parse(localStorage.getItem("authRes"));
	const [show, setShow] = useState(false);
	const [formValues, setFormValues] = useState({});
	const [kassiErrModal, setKassiErrorModal] = useState(false);
	const { initialized, initializing, initTrxData, walletTFsuccess } =
		useSelector((state) => state.transaction);
	const [redirecting, setRedirecting] = useState(false);
	const [htmlContent, setHtmlContent] = useState("");
	const [payinExtra, setPayinExtra] = useState(null);
	const transaction_limit = authRes?.country?.countrylimit?.amount;
	const formikRef = useRef();
	useEffect(() => {
		if (initialized) {
			const transaction_type = localStorage.getItem("trx_type") ?? "";
			if (initTrxData.for_payin.plugin === "poli") {
				setRedirecting(true);
				if (!initTrxData.for_payin.NavigateURL) navigate("/transaction-failed");
				if (initTrxData.for_payin.plugin === "poli")
					window.location.replace(initTrxData.for_payin.NavigateURL);
			} else if (transaction_type === "wallet") {
				return;
			} else {
				navigate("/transaction-notice");
			}
		}
		const payins = JSON.parse(localStorage.getItem("payins"));
		const selectedPayinUUID = localStorage.getItem("trx_selectedPayinUUID");
		for (let i = 0; i < payins?.length; i++) {
			const payin = payins[i];
			if (payin.uuid === selectedPayinUUID) {
				setPayinExtra(payin.extras);
				break;
			}
		}
		// const payin = payins.find(e => e.uuid === selectedPayinUUID);
	}, [initialized]);

	useEffect(() => {
		if (payinExtra) {
			const sanitizedHTML = DOMPurify.sanitize(payinExtra, {
				USE_PROFILES: { html: true },
			});
			setHtmlContent(sanitizedHTML);
		}
	}, [payinExtra]);
	const transaction_type = localStorage.getItem("trx_type") ?? "";

	const getSelectedReason = (reasonLabel) => {
		const selectedReason = trxMetas?.reason?.find(
			(reason) => reason?.purpose === reasonLabel
		);
		return selectedReason;
	};

	let complete_trnsac = <TransactionReceiptNew />;
	let complete_finish = initialized;

	if (complete_finish && complete_finish) {
		return complete_trnsac;
	}
	//

	return (
		<div className="container-fluid">
			<p className="text-info mb-4">
				<i className="fas fa-exclamation-circle" /> &nbsp; Generally, we
				disclose your personal information to organisations that help us provide
				our services to you. Such as Australian Government Finance Intelligence
				Agencies (AUSTRAC) and online identity verification and authentication
				providers. Such as Experian Australia Credit Services Pty Ltd.
			</p>
			<h6 className="fw-bolder mb-5">
				Send to <span className="text-red">{receiver?.fullname}</span>
			</h6>
			<div className="row">
				{/* left hand side */}
				<div className="col-xl-6 px-xxl-5 px-xl-4 mb-4">
					<div className="card border-0 rounded">
						<div className="card-body py-4 px-sm-5">
							<h6 className="fw-bolder text-center mb-4">
								Complete transaction
							</h6>
							<Formik
								innerRef={formikRef}
								initialValues={{
									account_id: "",
									reason: "",
									source_of_fund: "",
									checkbox0: "",
									checkbox1: "",
									checkbox2: "",
								}}
								validationSchema={Yup.object({
									account_id: Yup.string("").required("Select account"),
									reason: Yup.string("").required("Select transaction reason"),
									source_of_fund: Yup.string("").required("Select fund source"),
									checkbox0: Yup.string("").required("Check to proceed"),
									checkbox1: Yup.string("").required("Check to proceed"),
									checkbox2: Yup.string("").required("Check to proceed"),
								})}
								onSubmit={(values) => {
									if (localStorage.getItem("trx_selectedPayinName") == "Poli") {
										setFormValues(values);
										setShow(true);
									} else {
										dispatch(
											intializeTrx({
												...values,
												purpose_of_transaction_id:
													getSelectedReason(values?.reason)?.id ?? "",
											})
										);
									}
								}}
							>
								{(props) => (
									<form onSubmit={props.handleSubmit}>
										{/* select box */}
										<div className="form-group mb-3">
											<label className="mb-2">
												Select a beneficiary account
											</label>
											<select
												name="account_id"
												className="form-select min-height"
												onChange={(e) => {
													
														props.setValues({
															...props.values,
															account_id:
																e.target.options[e.target.selectedIndex].value,
														});
													
												}}
												onBlur={props.handleBlur}
											>
												<option value="">Select Account</option>
												{receiver?.accounts?.map((el) => {
													return (
														<option
															value={el.id}
															key={el.id}
															kaasiMetas={el.kaasi_metas}
														>{`${el.account_name} - ${el.account_number} (${el.bank_name})`}</option>
													);
												})}
											</select>
											{props.touched.account_id && props.errors.account_id && (
												<FormikError msg={props.errors.account_id} />
											)}
										</div>
										{/* select box */}
										{/* <div className="form-group mb-3">
                  <label className="mb-2">Pick up currency</label>
                  <select name id className="form-select min-height">
                    <option value>Select currency</option>
                    <option value="option 1">option 1</option>
                  </select>
                </div> */}
										{/* select box */}
										<div className="form-group mb-3">
											<label className="mb-2">Source of funds</label>
											<select
												name="source_of_fund"
												className="form-select min-height"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											>
												<option value="">Select Source of funds</option>
												{trxMetas?.source_of_funds?.values?.map((el, i) => {
													return (
														<option key={i} value={el}>
															{el}
														</option>
													);
												})}
											</select>
											{props.touched.source_of_fund &&
												props.errors.source_of_fund && (
													<FormikError msg={props.errors.source_of_fund} />
												)}
										</div>
										{/* select box */}
										<div className="form-group mb-5">
											<label className="mb-2">Reason</label>
											<select
												name="reason"
												className="form-select min-height"
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											>
												<option value="">Select Reason</option>
												{trxMetas?.reason?.map((el, i) => {
													return (
														<option key={i} value={el?.purpose}>
															{el?.purpose}
														</option>
													);
												})}
											</select>
											{props.touched.reason && props.errors.reason && (
												<FormikError msg={props.errors.reason} />
											)}
										</div>
										<div dangerouslySetInnerHTML={{ __html: htmlContent }} />
										<div className="form-group">
											<div className="form-check mb-3">
												<input
													className="form-check-input"
													type="checkbox"
													name="checkbox0"
													id="checkbox0"
													value="true"
													onChange={(e) => {
														if (e.target.checked) {
															props.setValues({
																...props.values,
																checkbox0: "yes",
															});
														} else {
															props.setValues({
																...props.values,
																checkbox0: "",
															});
														}
													}}
												/>
												<label className="fw-bold" htmlFor="checkbox0">
													Transaction will be completed upon payment
													confirmation in our bank account. Large/first-time
													payments may take up to 24 hours. Also, the rate is
													locked with a transaction, it will not be changed or
													altered even when the transaction has failed,
													cancelled or expired.
												</label>
											</div>

											<div className="form-check mb-3">
												<input
													className="form-check-input"
													type="checkbox"
													name="checkbox1"
													id="checkbox1"
													value="true"
													onChange={(e) => {
														if (e.target.checked) {
															props.setValues({
																...props.values,
																checkbox1: "yes",
															});
														} else {
															props.setValues({
																...props.values,
																checkbox1: "",
															});
														}
													}}
												/>
												<label
													className="form-check-label text-red"
													htmlFor="checkbox1"
												>
													Please check your transaction details carefully, go
													back and edit if necessary. Incorrect payment may not
													be recovered. Checking this box means you agree to
													continue and accept our{" "}
													<TermsModal>
														<a
															href="#"
															className="text-decoration-underline text-red"
														>
															Terms & Conditions
														</a>
													</TermsModal>
												</label>
											</div>
											<div className="form-check mb-4">
												<input
													className="form-check-input"
													type="checkbox"
													name="checkbox2"
													id="checkbox2"
													value="true"
													onChange={(e) => {
														if (e.target.checked) {
															props.setValues({
																...props.values,
																checkbox2: "yes",
															});
														} else {
															props.setValues({
																...props.values,
																checkbox2: "",
															});
														}
													}}
												/>
												<label
													className="form-check-label text-red"
													htmlFor="checkbox2"
												>
													Transaction of ${transaction_limit} or more per month
													may require additional proof of source of
													funds(Payslips, Bank statement). This does not take
													into account your personal need or objective.
												</label>
											</div>
										</div>
										<div className="d-flex flex-wrap justify-content-between">
											<button
												onClick={(e) => {
													e.preventDefault();
													navigate(-1);
												}}
												className="btn btn-dark d-block text-center text-white min-height"
												style={{ width: "30%" }}
												disabled={initializing || redirecting}
											>
												Back
											</button>
											<button
												type="submit"
												className="btn btn-red min-height"
												style={{ width: "65%" }}
												disabled={initializing || redirecting}
											>
												{redirecting ? "Redirecting to Poli.." : "Finish"}{" "}
												{initializing || redirecting ? <Spin /> : ""}
											</button>
										</div>
									</form>
								)}
							</Formik>
						</div>
					</div>
				</div>
				{/* right hand side */}
				<div className="col-xl-6 px-xxl-5 px-xl-4 mb-4">
					<div className="py-1">
						{/* you send */}
						<div className="input-group mb-3">
							<div className="form-floating w-75">
								<input
									type="text"
									className="form-control rounded-end-0 border-end-0"
									id="youSend"
									placeholder={0}
									defaultValue={localStorage.getItem("trx_sendVal")}
									disabled
								/>
								<label htmlFor="youSend">You send</label>
							</div>
							<span className="input-group-text end">
								<span
									className={`flag-icon flag-icon-${localStorage
										.getItem("trx_fromCountryIso")
										.toLowerCase()} flag-icon-squared`}
								></span>
							</span>
						</div>
						{/* payment method */}
						<div className="form-floating">
							<select id="paymentMethod" className="form-select" disabled>
								<option value>
									{localStorage.getItem("trx_selectedPayinName")}
								</option>
							</select>
							<label htmlFor="paymentMethod">Payment Method</label>
						</div>
						{/* conversion details */}
						<ul className="list-unstyled hero-card-details bg-red-light p-3 rounded my-3">
							{/* Guaranteed Rates  */}
							<li className="d-flex px-4 justify-content-between py-2">
								<small className="text-muted">
									Current Rates <span className="text-dark">(24 hrs)</span>{" "}
								</small>
								<span>
									1 {localStorage.getItem("trx_fromCountryCurency")} ={" "}
									{localStorage.getItem("trx_rate")}
									{localStorage.getItem("trx_toCurrency")}
								</span>
							</li>
							{/* fee */}
							<li className="d-flex px-4 justify-content-between py-2">
								<small className="text-muted">Fee</small>
								<span>
									{localStorage.getItem("trx_fee")}{" "}
									{localStorage.getItem("trx_fromCountryCurency")}
								</span>
							</li>
							{/* amount we'll convert too */}
							{/* <li className="d-flex px-4 justify-content-between py-2">
                <small className="text-muted">Amount We'll Convert</small>
                <span>200,000.00 AUD</span>
              </li> */}
							{/* time */}
							{/* <li className="d-flex px-4 justify-content-between py-2">
                <small className="text-muted">Estimated Time</small>
                <span>{localStorage.getItem("trx_eta")} mins</span>
              </li> */}
						</ul>
						{/* Reciepient Gets */}
						<div className="input-group mb-3">
							<div className="form-floating w-75">
								<input
									type="text"
									className="form-control rounded-end-0 border-end-0"
									id="reciepientGets"
									placeholder={0}
									defaultValue={localStorage.getItem("trx_receiveVal")}
									disabled
								/>
								<label htmlFor="reciepientGets">Reciepient Gets</label>
							</div>
							<span className="input-group-text end">
								<span
									className={`flag-icon flag-icon-${localStorage.getItem(
										"trx_toCountryIso"
									)} flag-icon-squared`}
								></span>
							</span>
						</div>
					</div>
					{!transaction_type && (
						<div className="mb-4">
							<p className="fw-bold">Bank Transfer/PayID is Seamless.</p>
							<p className="text-red">
								Our Bank transfer & PayID are now almost instant. We are
								committed to serving you better.
							</p>
							<p className="text-red">
								Please note that your transaction may not be processed
								immediately if you forget to input your{" "}
								<strong>Transaction Ref. NUMBER</strong> in the{" "}
								<strong>DESCRIPTION</strong> or the <strong>MESSAGE</strong> box
								when making a transfer to us.
							</p>
						</div>
					)}
				</div>
			</div>
			<Modal
				visible={show}
				onCancel={() => {
					setShow(false);
				}}
				closable={false}
				footer={false}
				style={{ top: 20 }}
			>
				<div className="modal-body text-center m-0 pt-0 pb-0">
					<p>
						Dear customer, Please remember to click the 'Return to CosmoRemit'
						at the final stage of your poli payment. Your transaction might be
						delayed if you forget to do so.
					</p>
					<p> Thank you.</p>
					<img src={poli} alt="delete icon" height={"25%"} width={"50%"} />
					<div class="d-block mt-3">
						<button
							className="btn btn-outline-red mx-2 px-4"
							type="button"
							onClick={() => {
								setShow(false);
							}}
						>
							Go back
						</button>
						<button
							className="btn btn-outline-red mx-2 px-4"
							type="button"
							onClick={() => {
								setShow(false);
								dispatch(intializeTrx(formValues));
							}}
						>
							Continue transaction
						</button>
					</div>
				</div>
			</Modal>
			<Modal
				visible={kassiErrModal}
				footer={false}
				onCancel={() => {
					setKassiErrorModal(false);
				}}
				zIndex={1090}
			>
				Sorry you can't send money to this account. Please update the account
				details and try again.
			</Modal>
			{/* <Successful showModal={walletTFsuccess} /> */}
		</div>
	);
}
