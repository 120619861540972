import React, { useEffect, useState } from "react";
import { fetchBeneficiaries } from "../../../store/actions/beneficiaryActions";
import { fetchDashboardMetas } from "../../../store/actions/transaction";
import { useSelector, useDispatch } from "react-redux";
import SendMoney from "../../../components/partials/sendMoney";
import content, * as notifications from "../../../components/notifications/notifications";
import { http } from "../../../config/axios";
import CreateBeneficiary from "../beneficiary/create";
import { Button, Modal, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import WalletInfoCard from "../../../components/partials/walletComponents/WalletInfoCard";
import FundWalletSuccessful from "../../../components/partials/walletComponents/modals/success/FundWalletSuccess";
import {
	closeFundSuccessModal,
	geUserWallets,
} from "../../../store/actions/walletActions";
import DashboardFeatureForNewUser from "../businessAccount/businessIntroFor NewUser";
import EditModal from "../beneficiary/editModal";
import { CheckCircleOutlined } from "@ant-design/icons";
import ReactMarkdown from "react-markdown";

export default function Dashboard() {
	const navigate = useNavigate();
	const [filterBeneficiary, setFilterBeneficiary] = useState([]);
	const [receiver, _setReceiver] = useState({});
	const [showNotification, setShowNotification] = useState(false);
	const dispatch = useDispatch();
	const { beneficiaries } = useSelector((state) => state.beneficiaryIndex);
	const { canUseWallet, fetched_user_wallet, wallet_funded, user_wallets } =
		useSelector((state) => state.wallet);
	const { face_verified } = useSelector((state) => state.personal);

	const { dashboardMetas } = useSelector((state) => state.transaction);
	const {
		// beneficiary,
		showModal,
	} = useSelector((state) => state.editBeneficiary);
	const [kassiErrModal, setKassiErrorModal] = useState(false);
	sessionStorage.setItem("send_option", "multiple");

	const profile = JSON.parse(localStorage.getItem("authRes"));

	useEffect(() => {
		if (beneficiaries.constructor === Array)
			setFilterBeneficiary([...beneficiaries]);
	}, [beneficiaries]);

	const user = JSON.parse(localStorage.getItem("authRes")) ?? {};
	if (!localStorage.getItem("popMessages")) {
		localStorage.setItem(
			"popMessages",
			JSON.stringify(user?.pop_messages ?? [])
		);
	}

	useEffect(() => {
		if (!user.phone_otp_verified_at || !user.email_otp_verified_at) {
			if (!user.phone_otp_verified_at) {
				navigate("/verify-phone");
			} else if (!user.email_otp_verified_at) {
				navigate("/verify-email");
			}
		} else {
			if (user?.profile_type === "aprofiles") {
				navigate("/dashboard");
			} else {
				if (
					!user?.profile_completed ||
					!user.profile.address_details_at ||
					!user.profile.personal_details_at ||
					!user.profile.kyc_details_at ||
					!face_verified
				) {
					navigate("/my-profile");
				} else {
					navigate("/dashboard");
				}
			}
		}
	}, [face_verified]);

	useEffect(() => {
		dispatch(fetchBeneficiaries(false));
		dispatch(fetchDashboardMetas);
		dispatch(geUserWallets());
	}, []);

	useEffect(() => {
		if (showModal) {
			const elements = document.getElementsByClassName("offcanvas-backdrop");
			for (let element of elements) {
				element.classList.remove("show");
			}
		}
	}, [showModal]);
	const setReceiver = (el) => {
		dispatch({ type: "SET_ERR", payload: "" });
		_setReceiver(el);
		localStorage.setItem("receiver", JSON.stringify(el));
	};

	const beneficiarySearch = (val) => {
		let ben = beneficiaries;
		if (!val) {
			setFilterBeneficiary(ben);
			return;
		}
		ben = ben.filter((el) =>
			el.fullname.toLowerCase().includes(val.toLowerCase())
		);
		setFilterBeneficiary(ben);
	};

	useEffect(() => {
		let popMessages = JSON.parse(localStorage.getItem("popMessages"));

		if (popMessages?.length > 0 && profile) {
			let type = popMessages[0].type;
			let msg = popMessages[0].message;
			let title = popMessages[0].title;

			const closePop = () => {
				const newPops = popMessages.filter(
					(el) => Number(el.id) !== Number(popMessages[0].id)
				);
				localStorage.setItem("popMessages", JSON.stringify(newPops));
				try {
					setShowNotification(!showNotification);
					http.post("settings/pop_message_read", { pop_id: popMessages[0].id });
				} catch (err) {}
			};

			const content = <ReactMarkdown>{msg}</ReactMarkdown>;

			if (type === "warning") {
				Modal.warning({
					title: title,
					// content: <p>{msg}</p>,
					content: content,
					okText: "I have read & agreed",
					onOk: () => closePop(),
					width: 600,
					closable: false,
					maskClosable: false,
					bodyStyle: {
						textAlign: "left",
					},
					style: { top: "20%" },
				});
			}

			if (type === "info") {
				Modal.info({
					title: title,
					content: content,
					okText: "I have read & agreed",
					onOk: () => closePop(),
					width: 600,
					closable: false,
					maskClosable: false,
					bodyStyle: {
						textAlign: "left",
					},
					style: { top: "20%" },
				});
			}

			if (type === "success") {
				Modal.success({
					title: title,
					content: content,
					okText: "I have read & agreed",
					onOk: () => closePop(),
					width: 600,
					closable: false,
					maskClosable: false,
					bodyStyle: {
						textAlign: "left",
					},
					style: { top: "20%" },
				});
			}

			if (type === "danger") {
				Modal.error({
					title: title,
					content: content,
					okText: "I have read & agreed",
					onOk: () => closePop(),
					width: 600,
					closable: false,
					maskClosable: false,
					bodyStyle: {
						textAlign: "left",
					},
					style: { top: "20%" },
				});
			}
		}
	}, [showNotification]);

	var classNames = require("classnames");
	const [activeTab, setActiveTab] = useState("sendMoney");

	const handleCloseFundSucessModal = () => {
		dispatch(closeFundSuccessModal());
	};
	return (
		<>
			{profile.has_business_account === true ? null : (
				<DashboardFeatureForNewUser />
			)}
			<section className="py-4">
				{
					<div className="container">
						{dashboardMetas?.sticky_messages?.map((el) => {
							return (
								<div className={`alert alert-${el.type} text-dark rounded`}>
									&nbsp;<b>{el.title}:</b> {el.message}
								</div>
							);
						})}

						<div className="row">
							{/* left hand side */}
							<div className="col-xl-6 px-xxl-5 px-xl-4 mb-4">
								<h5 className="fw-bold">
									Hello,{" "}
									<span className="fw-medium  ">
										{`${user.profile.firstname ?? ""}`}{" "}
									</span>{" "}
									👋
								</h5>
								<div className="row">
									{/* Wallet */}
									{!fetched_user_wallet ? (
										<Spin />
									) : canUseWallet &&
									  canUseWallet !== undefined &&
									  user_wallets?.length > 0 ? (
										<div className="mb-4">
											<WalletInfoCard location={"dashboard"} />
										</div>
									) : (
										<div className="col-xl-6 col-lg-12 col-sm-6">
											<div className="card rounded mb-4 bg-red text-white border-0">
												<div className="card-body py-4 ">
													<h6 className="text-light">Total money sent</h6>
													<h5 className="text-light">
														{new Intl.NumberFormat(undefined).format(
															dashboardMetas?.total_sent ?? 0
														)}
													</h5>
												</div>
											</div>
										</div>
									)}
								</div>

								<div className="card mb-4 rounded bg-light border-0">
									<div className="card-body py-4">
										<h6 className="fw-bold mb-4">Current Limit</h6>
										<div className="row mb-2">
											<div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3">
												<span className="text-muted">Fortnight Limit</span>
												<h5 className="fw-bold mb-0">{`${new Intl.NumberFormat(
													undefined
												).format(
													dashboardMetas?.limit_amount?.remaining_amount ?? 0
												)}
                       ${dashboardMetas?.limit_amount?.currency ?? 0}`}</h5>
											</div>

											<div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3">
												<span className="text-muted">Yearly Limit</span>
												<h5 className="fw-bold mb-0">
													{dashboardMetas?.country_limit?.yearly_limit == 0
														? "N/A"
														: dashboardMetas?.country_limit?.yearly_limit &&
														  new Intl.NumberFormat(undefined).format(
																dashboardMetas?.country_limit?.yearly_limit
														  )}{" "}
													{dashboardMetas?.country_limit?.yearly_limit != 0 &&
														dashboardMetas?.limit_amount?.currency}
												</h5>
											</div>
											<div className="col-xl-4 col-lg-6 col-sm-4 col-6 mb-3">
												<span className="text-muted">Fortnight Times</span>
												<h5 className="fw-bold mb-0">
													{new Intl.NumberFormat(undefined).format(
														dashboardMetas?.country_limit?.limit_days ?? 0
													)}{" "}
													<small>days</small>
												</h5>
											</div>
											<small>
												{dashboardMetas?.country_limit?.limit_times} times every
												fortnight
											</small>
										</div>
										<p>
											If you need higher limits permanently or temporarily,
											please contact our compliance team at
											compliance@cosmoremit.com.au
										</p>
									</div>
								</div>
							</div>
							{/* right hand side */}
							<div className="col-xl-6 px-xxl-5 px-xl-4 mb-4 d-none d-lg-block">
								<>
									<div className="card border-0 rounded shadow">
										<div className="card-body">
											<SendMoney page="user" receiver={receiver} />
										</div>
									</div>
								</>
							</div>
						</div>

						{/* send money button for mobile */}
						<a
							href="#offcanvasSendMoney"
							className="send-money-btn d-lg-none"
							data-bs-toggle="offcanvas"
						>
							<span className="send-money-img">
								<img src="../assets/images/icon/Group 933.png" alt />
							</span>
							<span className="send-money-text">Send Money</span>
						</a>

						{/* M O B I L E     S E N D     M O N E Y */}
						{!showModal && (
							<div
								className="offcanvas offcanvas-end"
								tabIndex={-1}
								id="offcanvasSendMoney"
								aria-labelledby="offcanvasNavbarLabel"
							>
								<div className="offcanvas-body pt-5">
									<div
										className={classNames("send-money-tab", {
											active: activeTab === "selectBeneficiary",
										})}
									>
										{/* title */}
										<div className="d-flex align-items-center">
											<div className="flex-shrink-0">
												<button
													type="button"
													className="btn text-red"
													data-bs-dismiss="offcanvas"
													aria-label="Close"
												>
													<i className="fas fa-arrow-left" />
												</button>
											</div>
											<div className="flex-grow-1 ms-3">
												<span className="send-money-title">Send money to</span>
											</div>
										</div>
										<form>
											<div className="input-group my-4">
												<span className="input-group-text text-muted bg-white">
													<i className="fas fa-search" />
												</span>
												<input
													type="text"
													placeholder="Search Beneficiary"
													className="form-control border-start-0 min-height"
													onChange={(e) => {
														beneficiarySearch(e.target.value);
													}}
												/>
											</div>
											{filterBeneficiary.map((el, i) => {
												let fullname = el?.fullname?.split(" ");
												if (fullname?.length > 1)
													fullname = fullname[0][0] + fullname[1][0];
												if (fullname?.length === 1) fullname = fullname[0][0];
												return (
													<a
														href="#send"
														style={{ cursor: "pointer" }}
														key={i}
														onClick={() => {
															if (!profile.can_transact) {
																notifications.warning({
																	title: "Warning",
																	msg: `Dear customer,

                              Thank you for choosing us,

                              Please be informed that your profile has not been fully verified

                              Kindly contact the team at <span class="text-red">compliance@cosmoremit.com.au</span> to activate your profile

                              Thank you`,
																});
																return;
															}

															if (el?.kaasi_metas) {
																setReceiver(el);
																setActiveTab("sendMoney");
															} else {
																setKassiErrorModal(true);
															}
														}}
														className="d-block border-bottom py-3"
													>
														<div className="d-flex align-items-center">
															<div className="flex-shrink-0">
																<span className="name-card fw-bold text-red">
																	{fullname}{" "}
																</span>
															</div>
															<div className="flex-grow-1 ms-3">
																<span className="d-block mb-1 text-dark">
																	{el.fullname}
																</span>
																<p className="text-muted mb-0">
																	<small className="pe-2">
																		<img
																			width={17}
																			src="../assets/images/flags/NGN.png"
																			alt
																		/>{" "}
																		NGN{" "}
																	</small>
																	&nbsp;
																	<small className="pe-2">
																		<img
																			width={17}
																			src="../assets/images/flags/Australia(1).png"
																			alt
																		/>{" "}
																		AUS{" "}
																	</small>
																</p>
															</div>
														</div>
													</a>
												);
											})}
										</form>
									</div>
									<div
										className={classNames("send-money-tab", {
											active: activeTab === "sendMoney",
										})}
									>
										{/* title */}
										<div className="d-flex">
											<div className="flex-shrink-0">
												<button
													type="button"
													data-bs-dismiss="offcanvas"
													aria-label="Close"
													className="btn text-red"
												>
													<i className="fas fa-arrow-left" />
												</button>
											</div>
											<div className="flex-grow-1 ms-3">
												{receiver?.fullname && (
													<span className="send-money-title">
														Send to{" "}
														<span className="text-red">
															{receiver?.fullname}
														</span>
													</span>
												)}
											</div>
										</div>
										<SendMoney page="user" receiver={receiver} />
									</div>
								</div>
							</div>
						)}
					</div>
				}
				{/* Kassi error modal*/}
				<Modal
					visible={kassiErrModal}
					footer={false}
					onCancel={() => {
						setKassiErrorModal(false);
					}}
					zIndex={1090}
				>
					Sorry you can't send money to this beneficiary. Please update the
					beneficiary details and try again.
				</Modal>
				<EditModal />
			</section>
		</>
	);
}
