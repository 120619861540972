import * as actionTypes from "../../actionTypes/admin/businessActionTypes";
export const businessReducer = (
  state = {
    businessCount: {
      verified: 0,
      unverified: 0,
    },
    verifiedBusinesses: [],
    loading: false,
    unverifiedBusinesses: [],
    businessData: {},
    verifyingBusiness: false,
  },
  action
) => {
  switch (action.type) {
    case actionTypes.SET_BUSINESS_COUNTS:
      return { ...state, businessCount: action.payload };
    case actionTypes.FETCHING_BUSINESSES:
      return { ...state, loading: action.payload };
    case actionTypes.SET_VERIFIED_BUSINESS:
      return { ...state, verifiedBusinesses: action.payload };
    case actionTypes.SET_UNVERIFIED_BUSINESS:
      return { ...state, unverifiedBusinesses: action.payload };
    case actionTypes.SET_BUSINESS_DATA:
      return { ...state, businessData: action.payload };
    case actionTypes.VERIFYING_BUSINESSES:
      return { ...state, verifyingBusiness: !state.verifyingBusiness };
    default:
      return state;
  }
};
