import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { logout } from "../../store/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { fetchActiveCurrencyWallets } from "../../store/actions/walletActions";
import { SET_CAN_USE_WALLET } from "../../store/actionTypes/walletActionTypes";
import {
  getFacialVerificationStatus,
  getUserProfile,
} from "../../store/actions/profileActions";
import { fetchCheckoutMetas } from "../../store/actions/transaction";
import { MdOutlineBusinessCenter } from "react-icons/md";

export default function DashboardSideNav() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  // const [BusinessAccount_IsCreated, setBusinessAccountIsCreated] =	useState(false);

  const { canUseWallet, currency_wallets, fetching_currencies_for_wallets } =
    useSelector((state) => state.wallet);

  const { face_verified } = useSelector((state) => state.personal);
  const { accountDeleted } = useSelector(
    (state) => state.deactivatingBusinessAccount
  );
  const profile = JSON.parse(localStorage.getItem("authRes"));

  const checkUserWalletEligibility = () => {
    const walletEligibility = currency_wallets?.find(
      (wallet) => wallet?.country.id === profile?.country.id
    );
    dispatch({ type: SET_CAN_USE_WALLET, payload: !!walletEligibility });
  };

  useEffect(() => {
    dispatch(fetchActiveCurrencyWallets());
    dispatch(getUserProfile());
    dispatch(getFacialVerificationStatus());
    dispatch(fetchCheckoutMetas);
  }, []);

  useEffect(() => {
    if (!face_verified || !profile?.profile_completed) {
      navigate("/my-profile");
    }
  }, [face_verified, location.pathname]);
  useEffect(() => {
    checkUserWalletEligibility();
  }, [currency_wallets, canUseWallet]);
  return (
    <section className="sidebar">
      {" "}
      <div className="sidebar-header">
        <NavLink to="#" className="sidebar-brand">
          <img
            src="../assets/images/logo/logo.png"
            className="sidebar-logo-full"
            width={120}
            alt="icon"
          />
          <img
            src="../assets/images/logo/logo_small.png"
            className="sidebar-logo-half"
            width={53}
            alt="icon"
          />
        </NavLink>
        <button
          type="button"
          className="sidebar-toggle-btn"
          onClick={() => {
            dispatch({ type: "TOGGLE_SIDE_BAR" });
          }}
        >
          <i className="fas fa-chevron-right sidebar-icon-open" />
          <i className="fas fa-chevron-left sidebar-icon-close" />
        </button>
      </div>
      <div className="sidebar-body">
        <ul className="sidebar-nav list-unstyled">
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="/dashboard"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/Vector.svg"
                  className="sidebar-icon-1"
                  alt="icon"
                />
                <img
                  src="../assets/images/icon/Vector.svg"
                  className="sidebar-icon-2"
                  width={21}
                  alt="icon"
                />
              </span>
              <span className="sidebar-text">Send</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="beneficiaries"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/cil_people.svg"
                  className="sidebar-icon-1"
                  alt="icon"
                />
                <img
                  src="../assets/images/icon/cil_people-red.png"
                  className="sidebar-icon-2"
                  width={20}
                  alt="icon"
                />
              </span>
              <span className="sidebar-text">Beneficiaries</span>
            </NavLink>
          </li>

          {canUseWallet && canUseWallet !== undefined && (
            <li className="sidebar-item">
              <NavLink
                to="wallet"
                className={`sidebar-link ${(isActive) =>
                  isActive ? "active" : ""}`}
              >
                <span className="sidebar-icon">
                  <img
                    src="../../assets/images/icon/wallet_icon.svg"
                    className="sidebar-icon-1"
                    alt="icon"
                  />
                  <img
                    src="../../assets/images/icon/wallet_icon.png"
                    className="sidebar-icon-2"
                    width={22}
                    alt="icon"
                  />
                </span>
                <span className="sidebar-text">
                  Wallet{" "}
                  <small className="mx-1 py-small px-2 bg-red-fade rounded-1 ">
                    New
                  </small>
                </span>
              </NavLink>
            </li>
          )}
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="transactions"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/mdi-light_view-dashboard.svg"
                  className="sidebar-icon-1"
                  alt="icon"
                />
                <img
                  src="../assets/images/icon/mdi-light_view-dashboard.png"
                  className="sidebar-icon-2"
                  width={20}
                  alt="icon"
                />
              </span>

              <span className="sidebar-text">Transactions</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="document-upload"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/la_file-upload.svg"
                  className="sidebar-icon-1"
                  alt="icon"
                />
                <img
                  src="../assets/images/icon/la_file-upload.png"
                  width={20}
                  className="sidebar-icon-2"
                  alt="icon"
                />
              </span>
              <span className="sidebar-text">Upload Document</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="/settings"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/clarity_settings-line.svg"
                  className="sidebar-icon-1"
                  alt="icon"
                />
                <img
                  src="../assets/images/icon/clarity_settings-line.png"
                  width={20}
                  className="sidebar-icon-2"
                  alt="icon"
                />
              </span>
              <span className="sidebar-text">Settings</span>
            </NavLink>
          </li>

          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to={profile.has_business_account ? "business-account" : "/create-business-account"}
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/business-icon-png-3.jpg"
                  className="sidebar-icon-1"
                  width={20}
                  alt="icon"
                />
                <img
                  src="../assets/images/icon/business-icon-png-3.jpg"
                  width={20}
                  className="sidebar-icon-2"
                  alt="icon"
                />
              </span>
              <span className="sidebar-text">Business Account</span>
            </NavLink>
          </li>
          {/* sidebar item */}
          <li className="sidebar-item">
            <NavLink
              to="../auth/login.html"
              className={`sidebar-link ${(isActive) =>
                isActive ? "active" : ""}`}
              onClick={() => {
                dispatch(logout);
              }}
            >
              <span className="sidebar-icon">
                <img
                  src="../assets/images/icon/simple-line-icons_logout.svg"
                  alt="icon"
                />
              </span>
              <span className="sidebar-text">Logout</span>
            </NavLink>
          </li>
        </ul>
      </div>
    </section>
  );
}
