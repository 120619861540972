import React, { useEffect, useState, useRef } from "react";
import List from "./list";
import { Modal, Select, Spin } from "antd";
import { useSelector, useDispatch } from "react-redux";
import {
  toggleCreateLocalRateModal,
  getPayins,
  getPayouts,
  createRate,
  search,
  getRateManagers,
} from "../../../store/actions/admin/rates";
import { Formik } from "formik";
import * as Yup from "yup";
import { fetchCountries } from "../../../store/actions/general";
import FormikError from "../../../components/partials/formikError";
import AdminSearchBar from "../../../components/partials/adminSearchBar";

export default function Rates() {
  useEffect(() => {
    dispatch(fetchCountries);
  }, []);
  const {
    showCreateModal,
    payins,
    payouts,
    fetchingPayins,
    fetchingPayouts,
    savedRates,
    savingRates,
    rateManagers,
  } = useSelector((state) => state.rates);
  const dispatch = useDispatch();
  const { msg } = useSelector((state) => state.error);
  const [fromCountry, setFromCountry] = useState("");
  const [toCountry, setToCountry] = useState("");
  const [selectedPayouts, setSelectedPayouts] = useState([]);
  const [selectedManager, setSelectedManager] = useState("");

  const [total, setTotal] = useState(0);

  const [defaultPayout, setDefaultPayout] = useState();

  const formikRef = useRef();

  const user = JSON.parse(localStorage.getItem("authRes"));
  const permissions = user.permissions;

  useEffect(() => {
    if (savedRates) {
      dispatch(toggleCreateLocalRateModal());
      document.getElementById("rateForm")?.reset();
      formikRef.current.setValues({
        from_country: "",
        to_country: "",
        buy: "",
        // sell: "",
        fee: "",
        maximum: "",
        minimum: "",
        promorate: "",
        times: "",
        islocal: "yes",
        payin_ids: [],
        payouts: [],
        deactivate: false,
        default: false,
      });
    }
    setFromCountry("");
    setToCountry("");
  }, [savedRates]);

  const findSelectedRateManager = (id) => {
    const manager = rateManagers?.find((manager) => manager.uuid === id);
    if (manager) {
      setSelectedManager(manager);
    }

    return manager;
  };

  const validationSchema = Yup.object({
    from_country: Yup.string().required("Select country"),
    to_country: Yup.string().required("Select country"),
    buy: Yup.number().required("Rate is required").typeError("Invalid input"),
    // sell:  Yup.number().required('Sell is required'),
    fee: Yup.number().required("Fee is required").typeError("Invalid input"),
    rate_manager_id: Yup.string(),
    additional_rate: Yup.number()
      .required("Additional rate is required")
      .typeError("Invalid input"),
    maximum: Yup.number()
      .required("Maximum is required")
      .typeError("Invalid input"),
    minimum: Yup.number()
      .required("Minimum is required")
      .typeError("Invalid input"),
    promorate: Yup.number()
      .required("Promo rate is required")
      .typeError("Invalid input"),
    times: Yup.number()
      .required("Times is required")
      .typeError("Invalid input"),
    payouts: Yup.array()
      .min(1, "Select at least one payout")
      .of(
        Yup.object().shape({
          payout_id: Yup.string().required(""),
          auto_settle: Yup.string().required(""),
          is_default: Yup.string().required(""),
        })
      ),
    payin_ids: Yup.array().min(1, "Select at least one payin"),
  });
  const initialValues = {
    from_country: "",
    to_country: "",
    buy: !!selectedManager ? selectedManager?.rate : "",
    rate_manager_id: "",
    // sell: "",
    fee: "",
    maximum: "",
    minimum: "",
    promorate: "",
    times: "",
    additional_rate: "",
    islocal: "yes",
    payin_ids: [],
    payouts: [],
    deactivate: false,
    default: false,
  };
  return (
    <div className="container">
      <h5 className="fw-bold">Rates</h5>
      <p className="text-muted mb-4">
        Here is a table containing a list of all your rates. <br /> You can
        delete and edit using the action button on the right.
      </p>
      <div className="row justify-content-between mb-4">
        {permissions["create rate"] && (
          <div className="col-sm order-sm-1 text-end mb-3">
            <button
              className="btn btn-white px-5 min-height"
              onClick={() => {
                dispatch(toggleCreateLocalRateModal());
              }}
            >
              Create Local Rate
            </button>{" "}
          </div>
        )}
        <AdminSearchBar callback={search} />
      </div>
      <List />
      {/* create local rate */}
      <Modal
        visible={showCreateModal}
        footer={false}
        style={{
          top: 20,
        }}
        width={"80%"}
        onCancel={() => {
          dispatch(toggleCreateLocalRateModal());
        }}
      >
        <h5 className="fw-bold">Create Local Rate</h5>

        <Formik
          initialValues={initialValues}
          innerRef={formikRef}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(createRate(values));
          }}
          enableReinitialize={true}
        >
          {(props) => {
            return (
              <form onSubmit={props.handleSubmit} id="rateForm">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>From-Amount</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        disabled
                        value={1}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Select From Country</label>
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return option.name
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        placeholder="Select country"
                        className="form-control min-height"
                        onChange={(value) => {
                          dispatch(getPayins(value));
                          setFromCountry(value);
                          if (toCountry && value) {
                            dispatch(
                              getRateManagers({ toCountry, fromCountry: value })
                            );
                          }
                          props.setValues({
                            ...props.values,
                            from_country: value,
                            payin_ids: [],
                          });
                        }}
                        value={fromCountry}
                        defaultValue={fromCountry}
                      >
                        {JSON.parse(
                          localStorage.getItem("cosmo_countries")
                        )?.map((el) => {
                          return (
                            <Select.Option
                              key={el.id}
                              id={el.id}
                              countryCode={el.phonecode}
                              name={el.name}
                            >
                              <span
                                className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                              ></span>
                              {"\u00A0"}
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      {props.touched.from_country &&
                        props.errors.from_country && (
                          <FormikError msg={props.errors.from_country} />
                        )}
                      <FormikError msg={msg?.from_country ?? ""} />
                    </div>
                  </div>
                  <div className="d-md-flex col-md-6 gap-4">
                    <div className="form-group w-100 mb-3">
                      <label>Select Rate Manager</label>
                      <Select
                        placeholder="Select a rate manager"
                        className="form-control min-height"
                        name="rate_manager_id"
                        onChange={(value) => {
                          props.setValues({
                            ...props.values,
                            rate_manager_id: value,
                          });
                        }}
                        value={props.values.rate_manager_id}
                        // defaultValue={initialVals.from_country}
                      >
                        {rateManagers?.map((manager) => (
                          <Select.Option
                            value={
                              manager.display_name === "Manual"
                                ? ""
                                : manager.uuid
                            }
                          >
                            {manager.display_name}
                          </Select.Option>
                        ))}
                      </Select>
                      {props.touched.rate_manager_id &&
                        props.errors.rate_manager_id && (
                          <FormikError msg={props.errors.rate_manager_id} />
                        )}
                      <FormikError msg={msg?.rate_manager_id ?? ""} />
                    </div>
                    <div className="form-group mb-3 w-100">
                      <label>Enter Rate</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="buy"
                        disabled={
                          !!findSelectedRateManager(
                            props.values.rate_manager_id
                          )
                        }
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.buy && props.errors.buy && (
                        <FormikError msg={props.errors.buy} />
                      )}
                      <FormikError msg={msg?.buy ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Select To Country</label>
                      <Select
                        showSearch
                        filterOption={(input, option) => {
                          return option.name
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        placeholder="Select country"
                        className="form-control min-height"
                        onChange={(value) => {
                          dispatch(getPayouts(value));
                          setToCountry(value);
                          if (value && fromCountry) {
                            dispatch(
                              getRateManagers({ toCountry: value, fromCountry })
                            );
                          }
                          props.setValues({
                            ...props.values,
                            to_country: value,
                          });
                        }}
                        value={toCountry}
                        disabled={fromCountry ? false : true}
                      >
                        {JSON.parse(
                          localStorage.getItem("cosmo_countries")
                        )?.map((el) => {
                          return (
                            <Select.Option
                              key={el.id}
                              id={el.id}
                              countryCode={el.phonecode}
                              name={el.name}
                            >
                              <span
                                className={`flag-icon flag-icon-${el.iso2.toLowerCase()} flag-icon-squared`}
                              ></span>
                              {"\u00A0"}
                              {el.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      {props.touched.to_country && props.errors.to_country && (
                        <FormikError msg={props.errors.to_country} />
                      )}
                      <FormikError msg={msg?.to_country ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Enter Fee </label>
                      {/* <label htmlFor>Enter Fee ({total})</label> */}
                      <input
                        type="number"
                        className="form-control min-height"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            fee: e.target.value,
                          });
                          // setTotal(total + Number(props.values.fee))
                        }}
                        onBlur={props.handleBlur}
                        name="fee"
                      />
                      {props.touched.fee && props.errors.fee && (
                        <FormikError msg={props.errors.fee} />
                      )}
                      <FormikError msg={msg?.fee ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Additional Rate</label>
                      {/* <label ></label>Enter Fee ({total})</label> */}
                      <input
                        type="text"
                        className="form-control min-height"
                        onChange={(e) => {
                          props.setValues({
                            ...props.values,
                            additional_rate: e.target.value,
                          });
                          // setTotal(total + Number(props.values.fee))
                        }}
                        onBlur={props.handleBlur}
                        name="additional_rate"
                        value={props.values.additional_rate}
                      />
                      {props.touched.additional_rate &&
                        props.errors.additional_rate && (
                          <FormikError msg={props.errors.additional_rate} />
                        )}
                      <FormikError msg={msg?.additional_rate ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Min. sender's amount</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="minimum"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.minimum && props.errors.minimum && (
                        <FormikError msg={props.errors.minimum} />
                      )}
                      <FormikError msg={msg?.minimum ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Max. sender's amount</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="maximum"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.maximum && props.errors.maximum && (
                        <FormikError msg={props.errors.maximum} />
                      )}
                      <FormikError msg={msg?.maximum ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>New user rate (Promorate)</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="promorate"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.promorate && props.errors.promorate && (
                        <FormikError msg={props.errors.promorate} />
                      )}
                      <FormikError msg={msg?.promorate ?? ""} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>New user times</label>
                      <input
                        type="number"
                        className="form-control min-height"
                        name="times"
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      />
                      {props.touched.times && props.errors.times && (
                        <FormikError msg={props.errors.times} />
                      )}
                      <FormikError msg={msg?.times ?? ""} />
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="_default"
                      name="default"
                      value={props.values.default}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.setValues({
                            ...props.values,
                            default: true,
                          });
                        } else {
                          props.setValues({
                            ...props.values,
                            default: false,
                          });
                        }
                      }}
                      onBlur={props.handleBlur}
                    />

                    <label className="form-check-label" htmlFor="_default">
                      Default
                    </label>
                  </div>
                  {props.touched.default && props.errors.default && (
                    <FormikError msg={props.errors.default} />
                  )}
                  <FormikError msg={msg?.default ?? ""} />
                </div>
                <div className="col-md-6">
                  <div className="form-check form-switch mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      id="deactivate"
                      name="deactivate"
                      value={props.values.deactivate}
                      onChange={(e) => {
                        if (e.target.checked) {
                          props.setValues({
                            ...props.values,
                            deactivate: true,
                          });
                        } else {
                          props.setValues({
                            ...props.values,
                            deactivate: false,
                          });
                        }
                      }}
                      onBlur={props.handleBlur}
                    />

                    <label className="form-check-label" htmlFor="deactivate">
                      Deactivate
                    </label>
                  </div>
                  {props.touched.deactivate && props.errors.deactivate && (
                    <FormikError msg={props.errors.deactivate} />
                  )}
                  <FormikError msg={msg?.deactivate ?? ""} />
                </div>

                <div className="row">
                  {/* payin */}
                  <div className="col-md-4">
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr className="border-top border-bottom">
                            <th />
                            <th className="text-uppercase text-muted">
                              Payins
                            </th>
                            <th className="text-uppercase text-muted">fee</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payins.map((el, i) => {
                            return (
                              <tr className="border-bottom">
                                <td>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value={el.id}
                                      name="payout_option"
                                      id="payin1check"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setTotal(total + Number(el.fee));
                                          props.setValues({
                                            ...props.values,
                                            payin_ids: [
                                              ...props.values.payin_ids,
                                              e.target.value,
                                            ],
                                          });
                                        } else {
                                          setTotal(total - Number(el.fee));
                                          let arr =
                                            props.values.payin_ids.filter(
                                              (el) => el !== e.target.value
                                            );
                                          props.setValues({
                                            ...props.values,
                                            payin_ids: arr,
                                          });
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label text-muted"
                                      htmlFor="payin1check"
                                    />
                                  </div>
                                </td>
                                <td>{el.name}</td>
                                <td>+ {el.fee}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {fetchingPayins && <Spin />}
                      {props.touched.payin_ids && props.errors.payin_ids && (
                        <FormikError msg={props.errors.payin_ids} />
                      )}
                      <FormikError msg={msg?.payin_ids ?? ""} />
                    </div>
                  </div>
                  {/* payout */}
                  <div className="col-md-8">
                    <div className="table-responsive">
                      <table className="table table-borderless">
                        <thead>
                          <tr className="border-top border-bottom">
                            <th />
                            <th
                              className="text-uppercase text-muted"
                              colSpan={2}
                            >
                              payouts
                            </th>
                            <th className="text-uppercase text-muted">
                              Auto Settle
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {payouts.map((el, i) => {
                            return (
                              <tr className="border-bottom" key={i}>
                                <td>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      name="payout_option"
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setSelectedPayouts([
                                            ...selectedPayouts,
                                            e.target.value,
                                          ]);
                                          let payoutObj = {
                                            payout_id: e.target.value,
                                            auto_settle: "no",
                                            is_default: true,
                                          };

                                          if (props.values.payouts.length < 1) {
                                            setDefaultPayout(e.target.value);
                                          }

                                          if (props.values.payouts.length > 0) {
                                            payoutObj = {
                                              payout_id: e.target.value,
                                              auto_settle: "no",
                                              is_default: false,
                                            };
                                          }

                                          props.setValues({
                                            ...props.values,
                                            payouts: [
                                              ...props.values.payouts,
                                              payoutObj,
                                            ],
                                          });
                                        } else {
                                          if (
                                            Number(e.target.value) ===
                                            Number(defaultPayout)
                                          )
                                            setDefaultPayout(0);
                                          let arr = props.values.payouts.filter(
                                            (el) =>
                                              el.payout_id !== e.target.value
                                          );
                                          props.setValues({
                                            ...props.values,
                                            payouts: arr,
                                          });

                                          let ar2 = selectedPayouts.filter(
                                            (el) => el !== e.target.value
                                          );
                                          setSelectedPayouts(ar2);
                                        }
                                      }}
                                      value={el.id}
                                      id={`payoutcheck${el.id}`}
                                      // checked={selectedPayouts.includes(el.id)}
                                    />
                                    <label
                                      className="form-check-label text-muted"
                                      htmlFor={`payoutcheck${el.id}`}
                                    />
                                  </div>
                                </td>
                                <td>{el.name}</td>
                                <td>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      value={el.id}
                                      name="payout_option"
                                      id="payout1Radio"
                                      disabled={
                                        selectedPayouts.includes(
                                          el.id.toString()
                                        )
                                          ? false
                                          : true
                                      }
                                      checked={
                                        Number(defaultPayout) === Number(el.id)
                                      }
                                      onChange={(e) => {
                                        if (e.target.checked) {
                                          setDefaultPayout(e.target.value);
                                          if (
                                            selectedPayouts.includes(
                                              e.target.value
                                            )
                                          ) {
                                            let newArr =
                                              props.values.payouts.map((el) => {
                                                if (
                                                  el.payout_id ===
                                                  e.target.value
                                                ) {
                                                  return {
                                                    ...el,
                                                    is_default: true,
                                                  };
                                                } else {
                                                  return {
                                                    ...el,
                                                    is_default: false,
                                                  };
                                                }
                                              });

                                            props.setValues({
                                              ...props.values,
                                              payouts: newArr,
                                            });
                                          } else {
                                            setSelectedPayouts([
                                              ...selectedPayouts,
                                              e.target.value,
                                            ]);

                                            let arr = props.values.payouts.map(
                                              (el) => {
                                                return {
                                                  ...el,
                                                  is_default: false,
                                                };
                                              }
                                            );
                                            let newArr = [
                                              ...arr,
                                              {
                                                payout_id: e.target.value,
                                                auto_settle: "no",
                                                is_default: true,
                                              },
                                            ];
                                            props.setValues({
                                              ...props.values,
                                              payouts: newArr,
                                            });
                                          }
                                        }
                                      }}
                                    />
                                    <label
                                      className="form-check-label text-muted"
                                      htmlFor="payout1Radio"
                                    >
                                      Select as default
                                    </label>
                                  </div>
                                </td>
                                <td>
                                  <select
                                    name=""
                                    className="form-select"
                                    disabled={
                                      selectedPayouts.includes(el.id.toString())
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      if (e.target.value) {
                                        let newArr = props.values.payouts.map(
                                          (el) => {
                                            if (
                                              el.payout_id === e.target.value
                                            ) {
                                              return {
                                                ...el,
                                                auto_settle: e.target.value,
                                              };
                                            }
                                            return el;
                                          }
                                        );

                                        props.setValues({
                                          ...props.values,
                                          payouts: newArr,
                                        });
                                      }
                                    }}
                                  >
                                    <option value="">--Select--</option>
                                    <option value="true">yes</option>
                                    <option value="false">no</option>
                                  </select>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      {fetchingPayouts && <Spin />}
                      {props.touched.payouts && props.errors.payouts && (
                        <FormikError msg={props.errors.payouts} />
                      )}
                      <FormikError msg={msg?.payouts ?? ""} />
                    </div>
                  </div>
                </div>

                <div className="text-end">
                  <button
                    className="btn btn-outline-red ms-2 px-4"
                    onClick={() => {
                      dispatch(toggleCreateLocalRateModal());
                    }}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-red ms-2 px-4"
                    disabled={savingRates}
                    type="submit"
                  >
                    Submit {savingRates && <Spin />}
                  </button>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}
