import * as actionTypes from "../actionTypes/transactionActionTypes";
import { http } from "../../config/axios";
import * as notifications from "../../components/notifications/notifications";
import * as error from "./errorActions";
import { store } from "../store";
import axios from "axios";

export const fetchDashboardMetas = async (dispatch) => {
	try {
		error.clearError(dispatch);
		dispatch({ type: actionTypes.FETCHING_DASHBOARD_METAS, payload: true });
		const res = await http.get("dashboard/meta");
		dispatch({ type: actionTypes.SET_DASHBOARD_METAS, payload: res.data.data });
		dispatch({ type: actionTypes.FETCHING_DASHBOARD_METAS, payload: false });
	} catch (err) {
		dispatch(error.setErrorMsg(err));
		console.log(err);
	}
};

export const fetchDefaultRates =
	(currentLocation, from_value, to_value) => async (dispatch) => {
		try {
			error.clearError(dispatch);
			dispatch({ type: actionTypes.FETCHING_DEFAULT_RATES, payload: true });
			dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: true });
			dispatch({ type: actionTypes.FETCED_DEFAULT_RATES, payload: false });
			const user = JSON.parse(localStorage.getItem("authRes"));
			const from_country =
				from_value ?? JSON.parse(localStorage.getItem("FROM_COUNTRY"));
			const to_country =
				to_value ?? JSON.parse(localStorage.getItem("TO_COUNTRY"));
			const url = buildUrl(user?.uuid, currentLocation);
			const params = buildParams(from_country, to_country);
			const res = await http.get(url, { params });
			localStorage.setItem("payins", JSON.stringify(res.data.data?.payins));
			dispatch({ type: actionTypes.FETCHING_DEFAULT_RATES, payload: false });
			dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
			dispatch({ type: actionTypes.FETCED_DEFAULT_RATES, payload: true });
			dispatch({ type: actionTypes.SET_DEFAULT_RATES, payload: res.data.data });
		} catch (err) {
			dispatch({ type: actionTypes.FETCHING_DEFAULT_RATES, payload: false });
			dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

const buildUrl = (userId, currentLocation) => {
	const baseUrl = `landing_page/default_landing/`;
	if (currentLocation === "/") return baseUrl;

	return userId ? `${baseUrl}?uuid=${userId}` : baseUrl;
};

const buildParams = (fromCountry, toCountry) => {
	const params = {};
	if (fromCountry && toCountry) {
		params.to_country = toCountry;
		params.from_country = fromCountry;
	}
	return params;
};
export const fetchCheckoutMetas = async (dispatch) => {
	try {
		error.clearError(dispatch);
		dispatch({ type: actionTypes.FETCHING_CHECKOUT_METAS, payload: true });
		dispatch({ type: actionTypes.FETCED_CHECKOUT_METAS, payload: false });
		const res = await http.get("metas/checkout_metas");
		// console.log(res.data.data);
		dispatch({ type: actionTypes.FETCHING_CHECKOUT_METAS, payload: false });
		dispatch({ type: actionTypes.FETCED_CHECKOUT_METAS, payload: true });
		localStorage.setItem("checkoutMetas", JSON.stringify(res.data.data));

		dispatch({ type: actionTypes.SET_CHECKOUT_METAS, payload: res.data.data });
	} catch (err) {
		console.log(err);
		dispatch({ type: actionTypes.FETCHING_CHECKOUT_METAS, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const fetchRatedCountries = () => async (dispatch) => {
	try {
		error.clearError(dispatch);
		dispatch({ type: actionTypes.FETCHING_RATED_COUNTRIES, payload: true });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: true });

		const res = await http.get("rate_calculator/populate_rated_countries");
		dispatch({ type: actionTypes.FETCHING_RATED_COUNTRIES, payload: false });
		dispatch({ type: actionTypes.SET_RATED_COUNTRIES, payload: res.data.data });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_RATED_COUNTRIES, payload: false });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });

		dispatch(error.setErrorMsg(err));
	}
};

export const fetchPayinsAttachedToRate = (fd) => async (dispatch) => {
	try {
		error.clearError(dispatch);
		dispatch({ type: "SET_ERR", payload: "" });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: true });

		dispatch({ type: actionTypes.FETCHING_RATE_PAYINS, payload: true });
		dispatch({ type: actionTypes.FETCHED_RATE_PAYINS, payload: false });
		const res = await http.get(
			`rate_calculator/populate_payins/${fd.from}/${fd.to}`
		);
		dispatch({ type: actionTypes.FETCHED_RATE_PAYINS, payload: true });
		dispatch({ type: actionTypes.FETCHING_RATE_PAYINS, payload: false });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });

		dispatch({ type: actionTypes.SET_RATE_PAYINS, payload: res.data.data });
	} catch (err) {
		dispatch({ type: actionTypes.FETCHING_RATE_PAYINS, payload: false });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const _inputFromAmount = (fd) => async (dispatch) => {
	try {
		dispatch({ type: "SET_ERR", payload: "" });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: true });
		let user = JSON.parse(localStorage.getItem("authRes"));
		error.clearError(dispatch);
		dispatch({ type: actionTypes.FETCHED_INPUT_FROM_AMOUNT, payload: false });
		const res = await http.post(`landing_page/send_amount`, {
			...fd,
			uuid: user?.uuid ?? "",
		});
		dispatch({ type: actionTypes.FETCHING_INPUT_FROM_AMOUNT, payload: false });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });

		dispatch({ type: actionTypes.FETCHED_INPUT_FROM_AMOUNT, payload: true });
		dispatch({
			type: actionTypes.SET_INPUT_FROM_AMOUNT,
			payload: res.data.data,
		});
	} catch (err) {
		// console.log(err.response.data.message);
		dispatch({ type: "SET_ERR", payload: err.response.data.message });
		dispatch({ type: actionTypes.FETCHING_INPUT_FROM_AMOUNT, payload: false });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
		dispatch(error.setErrorMsg(err, err.response.data.message));
	}
};

export const _inputRecepientAmount = (fd) => async (dispatch) => {
	try {
		dispatch({ type: "SET_ERR", payload: "" });
		error.clearError(dispatch);
		dispatch({
			type: actionTypes.FETCHING_INPUT_RECEPIENT_AMOUNT,
			payload: true,
		});
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: true });

		dispatch({
			type: actionTypes.FETCHED_INPUT_RECEPIENT_AMOUNT,
			payload: false,
		});
		let user = JSON.parse(localStorage.getItem("authRes"));
		const res = await http.post(`landing_page/receipient_amount`, {
			...fd,
			uuid: user?.uuid ?? "",
		});
		dispatch({
			type: actionTypes.FETCHED_INPUT_RECEPIENT_AMOUNT,
			payload: true,
		});
		dispatch({
			type: actionTypes.FETCHING_INPUT_RECEPIENT_AMOUNT,
			payload: false,
		});
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });

		dispatch({
			type: actionTypes.SET_INPUT_RECEPIENT_AMOUNT,
			payload: res.data.data,
		});
	} catch (err) {
		dispatch({ type: "SET_ERR", payload: err.response.data.message });
		dispatch({
			type: actionTypes.FETCHING_INPUT_RECEPIENT_AMOUNT,
			payload: false,
		});
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const _switchCountry = (fd) => async (dispatch) => {
	try {
		dispatch({ type: "SET_ERR", payload: "" });
		error.clearError(dispatch);
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: true });
		dispatch({
			type: actionTypes.FETCHING_SWITCH_COUNTRY,
			payload: true,
		});
		dispatch({
			type: actionTypes.FETCHED_SWITCH_COUNTRY,
			payload: false,
		});
		let user = JSON.parse(localStorage.getItem("authRes"));
		const res = await http.post(`rate_calculator/switch_country`, {
			...fd,
			uuid: user?.uuid ?? "",
		});
		dispatch({
			type: actionTypes.FETCHED_SWITCH_COUNTRY,
			payload: true,
		});
		dispatch({
			type: actionTypes.FETCHING_SWITCH_COUNTRY,
			payload: false,
		});
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });

		dispatch({
			type: actionTypes.SET_SWITCH_COUNTRY,
			payload: res.data.data,
		});
	} catch (err) {
		dispatch({ type: "SET_ERR", payload: err.response.data.message });
		dispatch({ type: actionTypes.FETCHING_TRX_DATA, payload: false });
		dispatch({
			type: actionTypes.FETCHING_SWITCH_COUNTRY,
			payload: false,
		});
		// dispatch(error.setErrorMsg(err));
	}
};

export const intializeTrx = (fd) => async (dispatch) => {
	try {
		/**
		 * Inititate transaction if it fails try again by recall poli if it fails
		 * the second time redirect to transaction failed screen
		 */
		const transaction_type = localStorage.getItem("trx_type") ?? "";
		dispatch({ type: "INITIALIZING", payload: true });
		dispatch({ type: "INITIALIZED", payload: false });
		const res = await http.post("transactions/initiate_transaction", {
			wallet_uuid:
				transaction_type === "wallet"
					? localStorage.getItem("trx_selected_wallet_uuid")
					: "",
			reason: fd.reason,
			purpose_of_transaction_id: fd.purpose_of_transaction_id,
			source_of_fund: fd.source_of_fund,
			account_id: fd.account_id,
			payin_id:
				transaction_type !== "wallet"
					? localStorage.getItem("trx_selectedPayin")
					: "",
			from_country_id: localStorage.getItem("trx_fromCountry"),
			to_country_id: localStorage.getItem("trx_toCountry"),
			send_amount: localStorage.getItem("trx_sendVal"),
			receipient_amount: localStorage.getItem("trx_receiveVal"),
			rate: localStorage.getItem("trx_rate"),
			fee: localStorage.getItem("trx_fee"),
			transact_as: localStorage.getItem("transact_as"),
		});

		localStorage.setItem("trx_initData", JSON.stringify(res.data.data));

		if (
			!res.data.data.for_payin.NavigateURL &&
			res.data.data.for_payin.plugin === "poli"
		) {
			// if the plugin is polipull and url is null recall poli
			const res2 = await http.post("transactions/recall_poli", {
				ref: res.data.data.transaction_details.reference,
			});
			localStorage.setItem("trx_initData", JSON.stringify(res2.data.data));
		}
		if (transaction_type === "wallet") {
			dispatch({ type: "SHOW_WALLET_SUCCESSFUL", payload: true });
		}
		dispatch({ type: "INITIALIZING", payload: false });
		dispatch({ type: "INIT_TRX_DATA", payload: res.data.data });
		dispatch({ type: "INITIALIZED", payload: true });
	} catch (err) {
		console.log(err);
		dispatch(error.setErrorMsg(err));
		dispatch({ type: "INITIALIZING", payload: false });
	}
};

export const _transactions =
	(page = 1) =>
	async (dispatch) => {
		try {
			dispatch({ type: "LOADING", payload: true });
			const res = await http.get(
				`transactions/list_transactions/paginate/10?page=${page}`
			);
			dispatch({ type: "SET_TRANSACTIONS", payload: res.data.data.data });
			dispatch({ type: "LOADING", payload: false });
		} catch (err) {
			dispatch({ type: "LOADING", payload: false });
			dispatch(error.setErrorMsg(err));
		}
	};

export const search = (param) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING", payload: true });
		const res = await http.post(`transactions/search_transactions`, {
			param: param,
		});
		dispatch({ type: "SET_TRANSACTIONS", payload: res.data.data.data });
		dispatch({ type: "LOADING", payload: false });
	} catch (err) {
		dispatch({ type: "LOADING", payload: false });
		dispatch(error.setErrorMsg(err));
	}
};
export const filterByDate = (param) => async (dispatch) => {
	try {
		dispatch({ type: "LOADING", payload: true });
		const res = await http.post(`transactions/filter_transactions`, {
			param: param,
		});
		dispatch({ type: "SET_TRANSACTIONS", payload: res.data.data.data });
		dispatch({ type: "LOADING", payload: false });
	} catch (err) {
		dispatch({ type: "LOADING", payload: false });
		dispatch(error.setErrorMsg(err));
	}
};

export const poliSuccess = async (fd) => {
	try {
		const res = await http.post(`poli/successful`, { token: fd });
	} catch (err) {
		console.log(err.response.data.message);
	}
};

export const poliFailed = async (fd) => {
	try {
		const res = await http.post(`poli/failed`, { token: fd });
		return res.data;
	} catch (err) {
		console.log(err.response.data.message);
	}
};

export const poliCanceled = async (fd) => {
	try {
		const res = await http.post(`poli/cancelled`, { token: fd });
		// dispatch({type: actionTypes.TRANSACTION_CANCELLED, payload:res.data})
		return res.data;
	} catch (err) {
		console.log(err.response.data.message);
	}
};
