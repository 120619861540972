import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import {
	_inputFromAmount,
	_inputRecepientAmount,
	_switchCountry,
	fetchDefaultRates,
	fetchRatedCountries,
} from "../store/actions/transaction";
import { FETCHING_TRX_DATA } from "../store/actionTypes/transactionActionTypes";

export const useMoneyTransfer = (props) => {
	const [sendVal, setSendVal] = useState(100);
	const [receiveVal, setReceiveVal] = useState(0);
	const [fee, setFee] = useState(0);
	const [fromCountry, setFromCountry] = useState();
	const [fromCountryCurency, setFromCountryCurrency] = useState();
	const [fromCurrency, setFromCurrency] = useState();
	const [fromCountryListFilter, setFromCountryListFilter] = useState([]);
	const [fromCountryIso, setFromCountryIso] = useState("");
	const [toCountry, setToCountry] = useState();
	const [toCountryIso, setToCountryIso] = useState("");
	const [toCurrency, setToCurrency] = useState();
	const [toCountryListFilter, setToCountryListFilter] = useState([]);
	const [toSwapCountryListFilter, setToSwapCountryListFilter] = useState([]);
	const [toSwapCountry, setToSwapCountry] = useState("");
	const [toSwapCountryIso, setToSwapCountryIso] = useState("");
	const [toSwapCurrency, setToSwapCurrency] = useState();
	const [rate, setRate] = useState();
	const [eta, setEta] = useState(0);
	const [payins, setPayins] = useState([]);
	const [selectedPayin, setSelectedPayin] = useState();
	const [selectedPayinName, setSelectedPayinName] = useState();
	const [selectedPayinUUID, setSelectedPayinUUID] = useState();

	const dispatch = useDispatch();

	const { currency_wallets, fetching_currencies_for_wallets } = useSelector(
		(state) => state.wallet
	);

	const {
		ratedCountries,
		defaultRates,
		inputFromAmount,
		inputRecepientAmount,
		fetchedDefault,
		fetchedInputFromAmount,
		fetchedInputRecepientAmount,
		fetchedSwitchCountry,
		switchCountry,
		err,
		fetchingTrxData,
	} = useSelector((state) => state.transaction);

	const transactionType = localStorage.getItem("trx_type");
	const send_option = sessionStorage.getItem("send_option");

	useEffect(() => {
		const currentLocation = window.location.pathname;
		if (transactionType !== "swap") {
			dispatch(fetchDefaultRates(currentLocation));
		}
		dispatch(fetchRatedCountries());
		// reset initialied for send
		dispatch({ type: "INITIALIZED", payload: false });
	}, []);

	useEffect(() => {
		if (fetchedDefault) {
			setSendVal(defaultRates.send_amount);
			setReceiveVal(defaultRates.receipent_amount);
			setFee(defaultRates.fee);
			setEta(defaultRates.estimated_time);
			setFromCountry(defaultRates.from_country.id);
			setFromCountryCurrency(defaultRates.from_country.currency);
			setFromCountryIso(defaultRates.from_country.iso2);
			if (send_option === "multiple") {
				setToCountryIso(defaultRates.to_country.iso2);
				setToCountry(defaultRates.to_country.id);
				setToCurrency(defaultRates.to_country.currency);
			}
			setRate(defaultRates.rate);
			setFromCurrency(defaultRates.from_country.currency);
			setPayins(defaultRates.payins);
			setSelectedPayin(defaultRates?.default_payin?.id);
			setSelectedPayinName(defaultRates?.default_payin?.name);
			setSelectedPayinUUID(defaultRates.default_payin.uuid);

			localStorage.setItem("FROM_COUNTRY", defaultRates?.from_country?.id);
			localStorage.setItem("TO_COUNTRY", defaultRates?.to_country?.id);
			dispatch({
				type: "SET_RECEIVING_CURRENCY",
				payload: defaultRates?.to_country.currency,
			});
			dispatch({
				type: "SET_SENDING_CURRENCY",
				payload: defaultRates?.from_country.currency,
			});
			dispatch({ type: "SET_RATE", payload: defaultRates?.rate });
			dispatch({ type: "SET_PROMO_RATE", payload: defaultRates?.promorate });
		}
	}, [fetchedDefault]);

	useEffect(() => {
		setFromCountryListFilter(ratedCountries.from_countries);
		setToCountryListFilter(ratedCountries.to_countries);
	}, [ratedCountries]);

	useEffect(() => {
		if (fetchedInputFromAmount) {
			setFee(inputFromAmount?.fee);
			setEta(inputFromAmount?.estimated_time);
			setFromCountry(inputFromAmount?.from_country?.id);
			setFromCountryCurrency(inputFromAmount?.from_country?.currency);
			setToCountry(inputFromAmount?.to_country?.id);
			setToCountryIso(inputFromAmount?.to_country?.iso2);
			setToSwapCountry(inputFromAmount?.to_country?.id);
			setToSwapCountryIso(inputFromAmount?.to_country?.iso2);
			setReceiveVal(inputFromAmount?.receipent_amount);
			setRate(inputFromAmount?.rate);
			setFromCountryIso(inputFromAmount?.from_country?.iso2);
			dispatch({ type: "SET_RATE", payload: inputFromAmount?.rate });
			dispatch({ type: "SET_PROMO_RATE", payload: inputFromAmount?.promorate });
		}
	}, [fetchedInputFromAmount]);

	useEffect(() => {
		if (fetchedSwitchCountry) {
			setFee(switchCountry?.fee);
			setRate(switchCountry?.rate);
			setEta(switchCountry?.estimated_time);
			setFromCountry(switchCountry?.from_country.id);
			setFromCountryCurrency(switchCountry?.from_country.currency);
			setFromCountryIso(switchCountry?.from_country?.iso2);
			setToCountry(switchCountry?.to_country?.id);
			setToCountryIso(switchCountry?.to_country?.iso2);
			setToSwapCountry(switchCountry?.to_country?.id);
			setToSwapCountryIso(switchCountry?.to_country?.iso2);
			setReceiveVal(switchCountry?.receipent_amount);
			setSendVal(switchCountry?.send_amount);
			setPayins(switchCountry?.payins);
			if (!transactionType || transactionType === "swap") {
				setSelectedPayin(switchCountry?.default_payin?.id);
				setSelectedPayinName(switchCountry?.default_payin?.name);
				setSelectedPayinUUID(switchCountry?.default_payin?.uuid);
			}

			dispatch({ type: "SET_RATE", payload: switchCountry?.rate });
			dispatch({ type: "SET_PROMO_RATE", payload: switchCountry?.promorate });
		}
	}, [fetchedSwitchCountry]);

	useEffect(() => {
		if (fetchedInputRecepientAmount) {
			setFee(inputRecepientAmount?.fee);
			setRate(inputRecepientAmount?.rate);
			setEta(inputRecepientAmount?.estimated_time);
			setFromCountry(inputRecepientAmount?.from_country?.id);
			setFromCountryCurrency(inputRecepientAmount?.from_country?.currency);
			setFromCountryIso(inputRecepientAmount?.from_country?.iso2);
			setToCountry(inputRecepientAmount?.to_country?.id);
			setToCountryIso(inputRecepientAmount?.to_country?.iso2);
			setToSwapCountry(inputRecepientAmount?.to_country?.id);
			setToSwapCountryIso(inputRecepientAmount?.to_country?.iso2);
			setSendVal(inputRecepientAmount?.send_amount);

			dispatch({ type: "SET_RATE", payload: inputRecepientAmount?.rate });
			dispatch({
				type: "SET_PROMO_RATE",
				payload: inputRecepientAmount?.promorate,
			});
		}
	}, [fetchedInputRecepientAmount]);

	const debounced = useDebouncedCallback((value, callback) => {
		dispatch(callback);
	}, 2000);

	const inputChangeHandler = (e) => {
		dispatch({ type: FETCHING_TRX_DATA, payload: true });
		if (e.target.name === "up") {
			setSendVal(e.target.value);
			debounced(
				e.target.value,
				_inputFromAmount({
					send_amount: e.target.value,
					from_country: fromCountry,
					to_country: toCountry ?? toSwapCountry,
					payin_id: selectedPayin,
				})
			);
		}

		if (e.target.name === "down") {
			setReceiveVal(e.target.value);
			debounced(
				e.target.value,
				_inputRecepientAmount({
					recipient_amount: e.target.value,
					from_country: fromCountry,
					to_country: toCountry ?? toSwapCountry,
					payin_id: selectedPayin,
					// send_amount: sendVal,
				})
			);
		}
	};

	const payinChange = (e) => {
		setSelectedPayin(e);
		const sel = payins?.find((el) => {
			return el?.id.toString() === e;
		});

		setSelectedPayinName(sel?.name);
		setSelectedPayinUUID(sel?.uuid);
		dispatch(
			_inputFromAmount({
				send_amount: sendVal,
				from_country: fromCountry,
				to_country: toCountry ?? toSwapCountry,
				payin_id: e,
			})
		);
	};

	const countryFromChange = (value) => {
		setFromCountry(value);
		dispatch(
			_switchCountry({
				from_country: value,
				to_country: toCountry ?? toSwapCountry,
				send_amount: sendVal,
			})
		);
	};

	const countryToChange = (value, sendingCountry) => {
		setToCountry(value);
		setToSwapCountry(value);
		dispatch(
			_switchCountry({
				from_country: sendingCountry ?? fromCountry,
				to_country: value,
				send_amount: sendVal,
			})
		);
	};

	const fromSearch = (val) => {
		let countries = [...ratedCountries.from_countries];
		if (!val) {
			setFromCountryListFilter(countries);
			return;
		}
		countries = countries.filter(
			(el) =>
				el.name.toLowerCase().includes(val.toLowerCase()) ||
				el.currency.toLowerCase().includes(val.toLowerCase())
		);
		setFromCountryListFilter(countries);
	};

	const toSearch = (val) => {
		let countries = [...ratedCountries.to_countries];
		if (!val) {
			setToCountryListFilter(countries);
			setToSwapCountryListFilter(countries);
			return;
		}
		countries = countries.filter(
			(el) =>
				el.name.toLowerCase().includes(val.toLowerCase()) ||
				el.currency.toLowerCase().includes(val.toLowerCase())
		);
		setToCountryListFilter(countries);
		setToSwapCountryListFilter(countries);
	};

	return {
		sendVal,
		inputChangeHandler,
		fromCountryIso,
		fromCountryCurency,
		fromSearch,
		fromCountryListFilter,
		setFromCountry,
		countryFromChange,
		fromCurrency,
		fromCountry,
		setFromCountryIso,
		setFromCountryCurrency,
		toCountryListFilter,
		receiveVal,
		toCountryIso,
		toSearch,
		toCurrency,
		setToCountryIso,
		setToCurrency,
		setToCountry,
		countryToChange,
		toCountry,
		toSwapCountry,
		toSwapCountryIso,
		toSwapCountryListFilter,
		toSwapCurrency,
		setToSwapCountry,
		setToSwapCountryIso,
		setToSwapCurrency,
		rate,
		fee,
		setRate,
		setFee,
		err,
		payinChange,
		selectedPayin,
		selectedPayinName,
		selectedPayinUUID,
		payins,
		eta,
		setSelectedPayin,
		setSelectedPayinName,
		setSelectedPayinUUID,
		currency_wallets,
		fetching_currencies_for_wallets,
		fetchedDefault,
		fetchingTrxData,
	};
};
