import { Modal } from "antd";
import moment from "moment";
import React from "react";

function ViewBusinessModal({ handleClose, visible, data }) {
  let img;
  if (data?.certificate_image?.basename) {
    const imageUrl = `${data?.certificate_image.dirname}/${data?.certificate_image.basename}`;
    img = imageUrl;
  }
  return (
    <Modal visible={visible} footer={false} onCancel={handleClose}>
      <h6 className="fw-bold">Business Details</h6>

      <div className="py-4">
        <p className="text-muted">User id: #{data.user_id}</p>
        <div className="table-responsive">
          <table className="table table-borderless mb-4">
            <thead>
              <tr>
                <th
                  className="text-uppercase text-muted text-center"
                  colspan="2"
                >
                  Business Data
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-bottom">
                <th>Business Reference id:</th>
                <td>{data.business_reference_id}</td>
              </tr>
              <tr className="border-bottom">
                <th>Business name</th>
                <td>{data?.business_name}</td>
              </tr>
              <tr className="border-bottom">
                <th>Business Location</th>
                <td>{data?.business_location}</td>
              </tr>
              <tr className="border-bottom">
                <th>Business category</th>
                <td>{data?.business_category}</td>
              </tr>
              <tr className="border-bottom">
                <th>Incorporation date</th>
                <td>{moment(data?.incorporation_date).format("YYYY-MM-DD")}</td>
              </tr>
              <tr className="border-bottom">
                <th>Jurisdiction code</th>
                <td>{data?.jurisdiction_code}</td>
              </tr>
              <tr className="border-bottom">
                <th>Business verification status</th>
                <td>{data?.business_verify_status}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h6 className="fw-bold">Certificate Image</h6>

          <div className="uploaded_image_preview">
            <img src={img} alt="Certificate Preview" />
          </div>
        </div>
        <div className="text-end mt-3">
          <button className="btn btn-red" type="button" onClick={handleClose}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ViewBusinessModal;
